import UtilDto, { IUtilDto } from "../UtilDto";

export interface IMemoDto extends IUtilDto {
  content?: string;
  image?: string;
  taskId?: string;
  mindmapId?: string;
  user?: any;
}

class MemoDto extends UtilDto {
    content?: string;
  image?: string;
  taskId?: string;
  mindmapId?: string;
  user;

  constructor(data: IMemoDto) {
    super(data);
    this.setData(data);
  }

  setData(data: IMemoDto) {
    this.content = data.content || "";
    this.image = data.image || "";
    this.taskId = data.taskId || "";
    this.mindmapId = data.mindmapId || "";
    this.user = data.user || "";
  }
}

export default MemoDto;