import { AuthService, RESUserDto } from '@api';
import { useRequest } from 'ahooks';
import { atom, useRecoilState } from 'recoil';

export const meProfileState = atom<RESUserDto>({
  key: 'meProfileState',
  default: undefined,
});


export const useMeProfile = () => {
  const [meProfile, setMeProfile] = useRecoilState(meProfileState);
  const getMeProfileRequest = useRequest(AuthService.authControllerMe, {
    manual: true,
  });

  function getMeProfile() {
    getMeProfileRequest.runAsync().then(setMeProfile)
  }

  return {
    meProfile,
    getMeProfile
  };
};
