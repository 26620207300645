import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IDashboardDto } from "../mapping/Dashboard/DashboardDto";
import { api } from "../util/api";
import { RootState } from './index';

interface DashboardState {
    isFetching: boolean
    item: IDashboardDto
    result: IDashboardDto[],
    selectDashboardId: string
}

const initialState: DashboardState = {
    isFetching: false,
    item: {},
    result: [],
    selectDashboardId: "",
}

const getListAllAction = createAsyncThunk("dashboard/getList", async (payload, { rejectWithValue }) => {
    try {
        const result = await api.get("/dashboard")
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const addDashboardAction = createAsyncThunk("dashboard/addDashboard", async (payload: IDashboardDto , { rejectWithValue }) => {
    try {
        const result = await api.post("/dashboard", {
            ...payload
        })
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const deleteDashboardAction = createAsyncThunk("dashboard/deleteDashboard", async ({id} : {id?: string} , { rejectWithValue }) => {
    try {
        const result = await api.delete(`/dashboard/${id}`)
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        selectDashboard: (state, action) => {
            state.selectDashboardId = action.payload || ""
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListAllAction.pending, state => {
                state.isFetching = true
            })
            .addCase(getListAllAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = action.payload as any;
            })
            .addCase(addDashboardAction.pending, state => {
                state.isFetching = true
            })
            .addCase(addDashboardAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = action.payload as any;
            })
            .addCase(addDashboardAction.rejected, (state) => {
                state.isFetching = false;
            })
            .addCase(deleteDashboardAction.pending, state => {
                state.isFetching = true
            })
            .addCase(deleteDashboardAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = action.payload as any;
            })
            .addCase(deleteDashboardAction.rejected, (state) => {
                state.isFetching = false;
            })
    },
});

export const DashboardAction = {
    getListAllAction,
    addDashboardAction,
    deleteDashboardAction
}

export const { selectDashboard } = dashboardSlice.actions;

export const dashboardSelector = (state: RootState) => state["dashboard"];

export default dashboardSlice.reducer;