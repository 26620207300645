import jsBlumpLib, { jsPlumbInstance } from "jsplumb";

export const jsBlumbInstance = () => {
    const blumbInstance = jsBlumpLib.jsPlumb.getInstance();
    blumbInstance.setContainer("block-column")
    blumbInstance.importDefaults({
        // Connector: ["Bezier"],
        // Connector: ["Flowchart", { stub: 15, cornerRadius: 5 }],
        // Anchors: ["Right", "Right"],
        ReattachConnections: true
        // Endpoint: ["Blank", {radius: 5}],
        // EndpointStyle: { fill: "#567567" },
    });
    return blumbInstance as jsPlumbInstance;
    // const blumb =  new jsBlumpLib.jsPlumbInstance()
    // return blumb;
}

export const insertAfterIndex = (arr: any[], index: number, newItem: any) => [
    ...arr.slice(0, index + 1),
    newItem,
    ...arr.slice(index + 1)
]

export const generateDuplicateArray = (array: any[], key: string) => {
    return array.filter(
        (value, index, self) =>
            index ===
            self.findIndex((t) => t[key] === value[key])
    );
};

export function removeObjectEmptyValue(obj) {
    return (
        obj &&
        Object.entries(obj)
            .filter(([_, v]) => !!v && (v as any[] || []).length > 0)
            .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
    );
}

export const createScrollStopListener = (element: HTMLElement, callback: Function, timeout: number = 200) => {
    let removed = false;
    let handle;
    const onScroll = () => {
        if (handle) {
            clearTimeout(handle);
        }
        handle = setTimeout(callback, timeout); // default 200 ms
    };
    element.addEventListener('scroll', onScroll);
    return () => {
        if (removed) {
            return;
        }
        removed = true;
        if (handle) {
            clearTimeout(handle);
        }
        element.removeEventListener('scroll', onScroll);
    };
};

export const generateDeepArray = (array: any[], childKey: string = "children", deepArray: any[] = []) => {
    array.map((item) => {
        item = { ...item };
        deepArray.push(item);
        if ((item[childKey] as any[] || [])?.length > 0) {
            generateDeepArray(item[childKey] as any[], childKey, deepArray);
        }
    });
    return deepArray;
};