import { CreateProjectDto } from "@api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from 'antd';
import ProjectDto, { IProjectDto } from "../mapping/Project/ProjectDto";
import { api } from "../util/api";
import { RootState } from './index';
import { UpdateProjectDto } from '../api/services/index';

interface ProjectState {
    isFetching: boolean
    item: IProjectDto
    result: IProjectDto[],
    page: number,
    size: number
}

const initialState: ProjectState = {
    isFetching: false,
    item: {},
    result: [],
    page: 1,
    size: 0
}

const getListByDashboardAction = createAsyncThunk("project/getList", async (params: { id: string, filter?: any }, { rejectWithValue }) => {
    try {
        const result = await api.get("/project/list/" + params.id, {
            params: params.filter || {}
        });
        return {
            ...result.data,
            content: (result.data?.content as IProjectDto[]).map(item => new ProjectDto(item))
        }
    } catch (e) {
        rejectWithValue(e);
    }
})

const create = createAsyncThunk("project/create", async (payload: CreateProjectDto, { rejectWithValue }) => {
    try {
        const result = await api.post("/project", payload);
        message.success("作成完了");
        return new ProjectDto(result.data);
    } catch (e) {
        rejectWithValue(e);
    }
});

const getDetail = createAsyncThunk("project/detail", async (id: string, { rejectWithValue }) => {
    try {
        const result = await api.get("/project/detail/" + id);
        return new ProjectDto(result.data);
    } catch (e) {
        rejectWithValue(e);
    }
})

const update = createAsyncThunk("project/update", async (params: { id: string, data: UpdateProjectDto }, { rejectWithValue, dispatch }) => {
    try {
        const result = await api.patch(`/project/${params?.id}`, params?.data)
            .then(res => {
                dispatch(getListByDashboardAction({
                    id: (params.data as any)?.dashboardId || "",
                    filter: {
                        userId: params?.data?.userId,
                        sort: 'ascend-createdOnDate',
                    }
                }));
                return res;
            });
        // dispatch(getDetail(params.id)).then(res => {
        //     message.success("updated")
        // })
        // message.success("updated")
        return new ProjectDto(result.data);
    } catch (e) {
        rejectWithValue(e);
    }
})
const remove = createAsyncThunk("project/remove", async (id: string, { rejectWithValue }) => {
    try {
        const result = await api.delete(`/project/${id}`);
        return result;
    } catch (e) {
        rejectWithValue(e);
    }
});
export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListByDashboardAction.pending, state => {
                state.isFetching = true
            })
            .addCase(getListByDashboardAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = (action.payload as any)?.content;
                state.page = (action.payload as any)?.page;
                state.size = (action.payload as any)?.size;
            })
            .addCase(create.pending, state => {
                state.isFetching = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = [...state.result, action.payload as IProjectDto];
            })
            .addCase(getDetail.pending, state => {
                state.isFetching = true
            })
            .addCase(getDetail.fulfilled, (state, action) => {
                state.isFetching = false;
                state.item = action.payload as IProjectDto
            })
            .addCase(update.pending, state => {
                state.isFetching = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = state.result.map(item => {
                    // if(item.id === action.payload)
                    return item
                })
            })
            .addCase(remove.pending, state => {
                state.isFetching = true
            })
            .addCase(remove.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(remove.rejected, (state, action) => {
                state.isFetching = false;
            })
    },
});

export const projectAction = {
    getListByDashboardAction,
    create,
    update,
    remove,
    getDetail
}

export const projectSelector = (state: RootState) => state["project"];

export default projectSlice.reducer;
