import axiosDefault, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { serviceOptions } from './services/serviceOptions';
import { STORAGES } from '../constants/StorageConst';

export class APIException {
  statusCode: number;

  message: string;

  error: string;

  constructor(error: { statusCode: number; message: string; error: string }) {
    this.statusCode = error.statusCode;
    this.message = error.message;
    this.error = error.error;
  }
}

const setInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.request.use(async (req) => {
    let originalRequest = req;
    if (!req.data) {
      delete req.data;
    }

    const token = localStorage.getItem(STORAGES.TOKEN);
    if (token) {
      originalRequest.headers.Authorization = 'Bearer ' + token;
      return Promise.resolve(originalRequest);
    }

    return req;
  })

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const response = error?.response?.data;
      if (response?.statusCode) {
        return Promise.reject(response);
      }
      return Promise.reject(error);
    }
  );
};

const baseURL = process.env.REACT_APP_API_URL;
const axios = axiosDefault.create({ baseURL });
serviceOptions.axios = axios;
setInterceptor(axios);

export const setAxiosConfig = (config: AxiosRequestConfig) => {
  const axios = axiosDefault.create({
    baseURL,
    ...config,
  });

  serviceOptions.axios = axios;
  setInterceptor(axios);
};

export * from './services';
