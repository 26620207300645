import UtilDto, { IUtilDto } from "../UtilDto";

export interface IStatusDto extends IUtilDto {
  name?: string;
  colorCode?: string;
  dashboardId?: string;
}

class StatusDto extends UtilDto {
    name?: string;
  colorCode?: string;
  dashboardId?: string;

  constructor(data: IStatusDto) {
    super(data);
    this.setData(data);
  }

  setData(data: IStatusDto) {
    this.name = data.name || "";
    this.colorCode = data.colorCode || "";
    this.dashboardId = data.dashboardId || "";
  }
}

export default StatusDto;