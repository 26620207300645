import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import KPIDto, { IKpiDto } from "../mapping/Kpi/KpiDto";
import { api } from "../util/api";
import { RootState } from './index';

interface KPIState {
    isFetching: boolean
    item: IKpiDto
    result: IKpiDto[],
    listKpi: any[],
    page: number,
    size: number
}

const initialState: KPIState = {
    isFetching: false,
    item: {},
    result: [],
    listKpi: [],
    page: 1,
    size: 0
}

const getListByDashboardAction = createAsyncThunk("kpi/getList", async ({id, filter} : {id: string, filter?: any}, { rejectWithValue }) => {
    try {
        const result = await api.get("/kpi/list/" + id, {
            params: filter || {}
        });
        return {
            ...result.data,
            content: (result.data?.content as IKpiDto[]).map(item => new KPIDto(item))
        }
    } catch (e) {
        rejectWithValue(e);
    }
})

const getListByDateAction = createAsyncThunk("kpi/getListByDate", async ({dashboardId, month, year}: {dashboardId: string, month: number, year: number}, { rejectWithValue }) => {
    try {
        const result = await api.get(`/kpi/${dashboardId}/${month}-${year}`);
        return result.data
    } catch (e) {
        rejectWithValue(e);
    }
})

export const kpiSlice = createSlice({
    name: 'kpi',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListByDashboardAction.pending, state => {
                state.isFetching = true
            })
            .addCase(getListByDashboardAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = (action.payload  as any)?.content;
                state.page = (action.payload  as any)?.page;
                state.size = (action.payload  as any)?.size;
            })
            .addCase(getListByDateAction.pending, state => {
                state.isFetching = true
                state.listKpi = []
            })
            .addCase(getListByDateAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.listKpi = _.get(action, "payload", []);
            })
            .addCase(getListByDateAction.rejected, (state, action) => {
                state.isFetching = false;
            })
    },
});

export const kpiAction = {
    getListByDashboardAction,
    getListByDateAction
}

export const kpiSelector = (state: RootState) => state["kpi"];

export default kpiSlice.reducer;