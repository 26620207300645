import { IDashboardDto } from 'mapping/Dashboard/DashboardDto';
import UtilDto, { IUtilDto } from '../UtilDto';

export interface IUserDto extends IUtilDto {
  email?: string;
  fullName?: string;
  furigana?: string;
  rank?: number;
  companyId?: string;
  avatar?: string;
  dashboardRanks?: {
    id?: string;
    rank?: number;
    dashboard?: IDashboardDto;
  }[];
}

class UserDto extends UtilDto {
  email?: string;
  fullName?: string;
  furigana?: string;
  rank?: number;
  companyId?: string;
  avatar?: string;
  dashboardRanks?: {
    id?: string;
    rank?: number;
  }[];

  constructor(data: IUserDto) {
    super(data);
    this.setData(data);
  }

  setData(data: IUserDto) {
    this.email = data.email || '';
    this.fullName = data.fullName || '';
    this.furigana = data.furigana || '';
    this.rank = data.rank || 0;
    this.companyId = data.companyId || '';
    this.avatar = data.avatar || '';
    this.dashboardRanks = data?.dashboardRanks || [];
  }
}

export default UserDto;
