import { IAnswerDto } from "mapping/Answer/AnswerDto";
import UtilDto, { IUtilDto } from "../UtilDto";

export interface IQuestionDto extends IUtilDto {
  content?: string;
  image?: string;
  taskId?: string;
  mindmapId?: string;
  user?: any;
  answers?: IAnswerDto[];
  isSeen?: boolean;
}

class QuestionDto extends UtilDto {
    content?: string;
  image?: string;
  taskId?: string;
  mindmapId?: string;
  user;
  answers?: IAnswerDto[];
  isSeen?: boolean;

  constructor(data: IQuestionDto) {
    super(data);
    this.setData(data);
  }

  setData(data: IQuestionDto) {
    this.content = data.content || "";
    this.image = data.image || "";
    this.taskId = data.taskId || "";
    this.mindmapId = data.mindmapId || "";
    this.user = data.user || {};
    this.answers = data.answers || [];
    this.isSeen = data.isSeen || false;
  }
}

export default QuestionDto;