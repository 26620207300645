import { useMeProfile } from './useMeProfile';

const enum UserRank {
  ADMIN = 1,
  MANAGER = 2,
  NORMAL = 3,
}

export const enum UserDbRank {
  MEMBER = 1,
  MANAGER = 2,
}

export const permissions = {
  dashboard: {
    create: [UserRank.MANAGER],
    update: [UserRank.MANAGER],
    delete: [UserRank.MANAGER],
  },
  dashboardTemplate: {
    create: [UserRank.MANAGER],
    update: [UserRank.MANAGER],
    delete: [UserRank.MANAGER],
  },
  kpi: {
    create: [UserRank.MANAGER],
    update: [UserRank.MANAGER],
    delete: [UserRank.MANAGER],
  },
  status: {
    create: [UserRank.MANAGER],
    update: [UserRank.MANAGER],
    delete: [UserRank.MANAGER],
  },
  company: {
    create: [UserRank.MANAGER],
    update: [UserRank.MANAGER],
    delete: [UserRank.MANAGER],
  },
  member: {
    create: [UserRank.MANAGER],
    update: [UserRank.MANAGER],
    delete: [UserRank.MANAGER],
  },
};

export const useMePermission = (permission: UserRank[]) => {
  const { meProfile } = useMeProfile();
  const hasPermission = permission.includes(meProfile?.rank);

  return hasPermission;
};
