import UtilDto, { IUtilDto } from "../UtilDto";
import UserDto from "../User/UserDto";
import StatusDto from "../Status/StatusDto";
import DashboardDto from "../Dashboard/DashboardDto";
import KpiDto from "../Kpi/KpiDto";
import ProjectDto from "mapping/Project/ProjectDto";
import { IDashboardDto } from '../Dashboard/DashboardDto';
import { IKpiDto } from '../Kpi/KpiDto';
import { IUserDto } from '../User/UserDto';
import { IStatusDto } from '../Status/StatusDto';
import { IProjectDto } from '../Project/ProjectDto';

export interface IDashboardDetailDto extends IUtilDto {
  dashboard?: IDashboardDto;
  kpis?: IKpiDto[];
  members?: IUserDto[];
  statuses?: IStatusDto[]
  projects?: IProjectDto[]
}

class DashboardDetailDto extends UtilDto {
  dashboard?: IDashboardDto;
  kpis?: IKpiDto[];
  members?: IUserDto[];
  statuses?: IStatusDto[];
  projects?: IProjectDto[]

  constructor(data: IDashboardDetailDto) {
    super(data);
    this.setData(data);
  }

  setData(data: IDashboardDetailDto) {
    this.dashboard = new DashboardDto(data.dashboard  as IDashboardDto) || {};
    this.kpis = data.kpis?.map(i => new KpiDto(i) as IKpiDto) || [];
    this.members = data.members?.map(i => new UserDto(i) as IUserDto) || [];
    this.statuses = data.statuses?.map(i => new StatusDto(i) as IStatusDto) || [];
    this.projects = data.projects?.map(i => new ProjectDto(i) as IProjectDto) || [];
  }
}

export default DashboardDetailDto;