import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { RootState, useAppDispatch } from '../../store';

import AppLocale from '../../lngProvider';
import MainApp from './MainApp';
import SignIn from '../../routes/auth/SignIn/SignIn';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from '../../constants/ThemeSetting';
import CircularProgress from '../../components/CircularProgress';
import { changePathname, setInitUrl } from '../../store/settingSlice';
import { ROUTES } from '../../constants/RouteConst';
import { me } from '../../store/authSlice';
import ForgotPassword from '../../routes/auth/ForgotPassword/ForgotPassword';
import RecoverPassword from '../../routes/auth/RecoverPassword/RecoverPassword';
import ConfirmForgotPassword from 'routes/auth/ConfirmForgotPassword/ConfirmForgotPassword';
import ConfirmRecoverPassword from 'routes/auth/ConfirmRecoverPassword/ConfirmRecoverPassword';
import queryString from 'query-string';

const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (authUser) {
        return <Component {...props} />;
      }
      return (
        <Redirect
          to={{
            pathname: ROUTES.SIGN_IN,
            state: { from: location },
          }}
        />
      );
    }}
  />
);

const AuthRoute = ({ component: Component, location, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!authUser) {
        return <Component {...props} />;
      } else if (location.search.includes('dashboardId')) {
        return (
          <Redirect
            to={{
              pathname: `/dashboard/${queryString.parse(location.search).dashboardId}`,
              state: { from: location },
            }}
          />
        );
      }
      return (
        <Redirect
          to={{
            pathname: authUser.rank === 1 ? ROUTES.MANAGEMENT_DASHBOARD : ROUTES.DASHBOARD_LIST,
            state: { from: location },
          }}
        />
      );
    }}
  />
);

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('full-layout');
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove('full-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('boxed-layout');
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('full-layout');
    document.body.classList.add('framed-layout');
  }
};

const setNavStyle = (navStyle) => {
  if (
    navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER
  ) {
    document.body.classList.add('full-scroll');
    document.body.classList.add('horizontal-layout');
  } else {
    document.body.classList.remove('full-scroll');
    document.body.classList.remove('horizontal-layout');
  }
};

const App = () => {
  const { locale, navStyle, layoutType, themeType, isDirectionRTL, initURL } = useSelector(
    (state: RootState) => state.setting
  );
  const { authUser, loading: isLoadingUser } = useSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const noReloadSignIn =
    !location.pathname.includes('recover-password') &&
    !location.search.includes('dashboardId');

  useEffect(() => {
    if (authUser) {
      dispatch(me());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(changePathname(location.pathname));
  }, [location, dispatch]);

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add('rtl');
      document.documentElement.setAttribute('data-direction', 'rtl');
    } else {
      document.documentElement.classList.remove('rtl');
      document.documentElement.setAttribute('data-direction', 'ltr');
    }
  }, [isDirectionRTL]);

  useEffect(() => {
    if (locale) document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    } else if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
    }
  }, [themeType]);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
  });

  useEffect(() => {
    if (!authUser) {
      if (localStorage.getItem('email-forgot')) {
        localStorage.removeItem('email-forgot');
      }
      if (noReloadSignIn) {
        history.push(ROUTES.SIGN_IN);
      }
    } else if (initURL === '/') {
      history.push(authUser?.rank === 1 ? ROUTES.MANAGEMENT_DASHBOARD : ROUTES.DASHBOARD_LIST);
    }
    // else {
    //   history.push(initURL);
    // }
  }, [authUser, initURL, history, noReloadSignIn]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const currentAppLocale = AppLocale['ja'];

  return isLoadingUser ? (
    <CircularProgress />
  ) : (
    <ConfigProvider locale={currentAppLocale.antd} direction={isDirectionRTL ? 'rtl' : 'ltr'}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <Switch>
          <AuthRoute
            authUser={authUser}
            location={location}
            exact
            path={ROUTES.SIGN_IN}
            component={SignIn}
          />
          <AuthRoute
            authUser={authUser}
            location={location}
            exact
            path={ROUTES.FORGOT_PASSWORD}
            component={ForgotPassword}
          />
          <AuthRoute
            authUser={authUser}
            location={location}
            exact
            path={`${ROUTES.RECOVER_PASSWORD}/:token`}
            component={RecoverPassword}
          />
          <AuthRoute
            authUser={authUser}
            location={location}
            exact
            path={ROUTES.CONFIRM_FORGOT_PASSWORD}
            component={ConfirmForgotPassword}
          />
          <AuthRoute
            authUser={authUser}
            location={location}
            exact
            path={ROUTES.CONFIRM_RECOVER_PASSWORD}
            component={ConfirmRecoverPassword}
          />
          <RestrictedRoute
            path={`${match.url}`}
            authUser={!location ? undefined : authUser}
            location={location}
            component={MainApp}
          />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default App;
