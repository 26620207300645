export const ROUTES = {
  USER_LIST: "/dashboard/:dashboardId/user",
  USER_CREATE: "/dashboard/:dashboardId/user/create",
  USER_DETAIL: "/dashboard/:dashboardId/user/detail",
  COMPANY_LIST: "/company",
  SIGN_IN: "/signin",
  FORGOT_PASSWORD: "/forgot-password",
  CONFIRM_FORGOT_PASSWORD: "/confirm-forgot-password",
  RECOVER_PASSWORD: "/recover-password",
  CONFIRM_RECOVER_PASSWORD: "/confirm-recover-password",
  CHANGE_PASSWORD: "/change-password",
  CONFIRM_CHANGE_PASSWORD: "/confirm-change-password",
  DASHBOARD: "/dashboard/:dashboardId",
  DASHBOARD_EDIT_KPI: "/dashboard/:dashboardId/edit",
  DASHBOARD_KPI_DETAIL: "/dashboard/:dashboardId/detail",
  PROJECT: "/dashboard/:dashboardId/project",
  TASKBOARD: "/dashboard/:dashboardId/taskboard",
  TASKLIST: "/dashboard/:dashboardId/taskboard/tasklist",
  DASHBOARD_SETTING: "/dashboard/:dashboardId/setting",
  DASHBOARD_CREATE: "/dashboard/create",
  DASHBOARD_INVITE_USER: "/dashboard/:dashboardId/invite-user",
  DASHBOARD_LIST: "/dashboard",
  MANAGEMENT_DASHBOARD: "/management/dashboard",
  MANAGEMENT_COMPANY: "/management/company"
};
