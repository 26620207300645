import UtilDto, { IUtilDto } from "../UtilDto";

export interface IProjectDto extends IUtilDto {
  title?: string;
  memo?: string;
  kpiId?: string;
  userId?: string;
  kpi?: any;
  user?: any;
  sampleMessage?: string;
  sampleImage?: string;
  connectKpiId?: string;
  originalKpiId?: string;
  originalConnectKpiId?: string;
  connectKpi?: any;
  targetValue?: number;
  processValue?: number;
}

class ProjectDto extends UtilDto {
  title?: string;
  memo?: string;
  kpiId?: string;
  userId?: string;
  kpi;
  user;
  sampleMessage?: string;
  sampleImage?: string;
  connectKpiId?: string;
  connectKpi?: any;
  originalKpiId?: string;
  originalConnectKpiId?: string;
  targetValue?: number;
  processValue?: number;

  constructor(data: IProjectDto) {
    super(data);
    this.setData(data);
  }

  setData(data: IProjectDto) {
    this.title = data?.title || "";
    this.memo = data?.memo || "";
    this.kpiId = data?.kpiId || "";
    this.userId = data?.userId || "";
    this.kpi  = data?.kpi || {};
    this.user = data?.user || {};
    this.sampleMessage = data?.sampleMessage || "";
    this.sampleImage = data?.sampleImage || "";
    this.connectKpiId = data?.connectKpiId || "";
    this.connectKpi = data?.connectKpi || "";
    this.originalKpiId = data?.originalKpiId || "";
    this.originalConnectKpiId = data?.originalConnectKpiId || "";
    this.targetValue = data?.targetValue || 0;
    this.processValue = data?.processValue || 0;
  }
}

export default ProjectDto;