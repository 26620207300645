import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
// import { useMount } from 'ahooks';
import { useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ROUTES } from '../constants/RouteConst';
import { RootState } from '../store';
import asyncComponent from '../util/asyncComponent';
import ListDashboard from './DashboardList/ListDashboard';
library.add(fas);

const App = ({ match, sidebarCollapsed, showSidebar, ...rest }) => {
  const userInfor = useSelector((state: RootState) => state.auth.authUser);
  const location = useLocation();

  const noPadding =
    location.pathname.includes('/change-password') ||
    location.pathname.includes('/confirm-change-password') ||
    location.pathname.includes('/invite-user');

  return (
    <div style={{ paddingLeft: !sidebarCollapsed && !noPadding ? '153px' : '0' }}>
      {userInfor?.rank === 1 ? (
        <Switch>
          <Route
            exact
            path={ROUTES.MANAGEMENT_COMPANY}
            component={asyncComponent(() => import('./Management/Company/ListCompany')) as any}
          />
          <Route
            exact
            path={ROUTES.MANAGEMENT_DASHBOARD}
            component={asyncComponent(() => import('./Management/Dashboard')) as any}
          />
        </Switch>
      ) : (
        <Switch>
          <Route exact path={ROUTES.DASHBOARD_LIST} component={() => <ListDashboard />} />
          <Route
            exact
            path={ROUTES.CHANGE_PASSWORD}
            component={asyncComponent(() => import('./auth/ChangePassword')) as any}
          />
          <Route
            exact
            path={ROUTES.CONFIRM_CHANGE_PASSWORD}
            component={asyncComponent(() => import('./auth/ConfirmChangePassword')) as any}
          />
          {userInfor?.rank === 2 && (
            <Route
              exact
              path={ROUTES.DASHBOARD_CREATE}
              component={asyncComponent(() => import('./DashboardList/CreateDashboard')) as any}
            />
          )}
          <Route
            exact
            path={`${ROUTES.COMPANY_LIST}`}
            component={asyncComponent(() => import('./company/listCompany')) as any}
          />
          <Route
            exact
            path={`${match.url}createCompany`}
            component={asyncComponent(() => import('./company/createCompany')) as any}
          />
          <Route
            path={`${ROUTES.DASHBOARD}`}
            component={asyncComponent(() => import('./dashboardItem/DashboardItem')) as any}
          />
        </Switch>
      )}
    </div>
  );
};

export default App;
