export interface IUtilDto {
  id?: string;
  createdOnDate?: Date;
  createdByUserId?: string;
  lastModifiedOnDate?: Date;
  lastModifiedByUserId?: string;
  deletedAt?: Date;
  createdBy?: any;
}

export interface IFilterDto {
  sort?: string
}

abstract class UtilDto {
  public id?: string;
  public createdOnDate?: Date;
  public createdByUserId?: string;
  public lastModifiedOnDate?: Date;
  public lastModifiedByUserId?: string;
  public deletedAt?: Date;
  public createdBy;

  constructor(data: IUtilDto) {
    this.id = data?.id || "";
    this.createdOnDate = data?.createdOnDate;
    this.createdByUserId = data?.createdByUserId || "";
    this.lastModifiedOnDate = data?.lastModifiedOnDate;
    this.lastModifiedByUserId = data?.lastModifiedByUserId || "";
    this.deletedAt = data?.deletedAt;
    this.createdBy = data?.createdBy || "";
  }
}

export default UtilDto;
