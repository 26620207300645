import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IUserDto } from "../mapping/User/UserDto";
import { api } from "../util/api";
import { RootState } from './index';

interface UserState {
    isFetching: boolean
    item: IUserDto
    result: IUserDto
}

const initialState: UserState = {
    isFetching: false,
    item: {},
    result: {}
}

const getUserInfoAction = createAsyncThunk("user/getInfo", async (_, { rejectWithValue }) => {
    try {
        const result = await api.get(`/auth/me`)
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const updateUserAction = createAsyncThunk("user/updateUser", async ({fullName, avatar} : {fullName?: string, avatar?: string}, { rejectWithValue }) => {
    try {
        const result = await api.patch("/users/", {
            fullName,
            avatar
        })
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const resetAvatarAction = createAsyncThunk("user/resetAvatar", async (_, { rejectWithValue }) => {
    try {
        const avatar = ""
        const result = await api.patch("/users/", {
            avatar
        })
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserInfoAction.pending, state => {
                state.isFetching = true
            })
            .addCase(getUserInfoAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = action.payload as any;
            })
            .addCase(updateUserAction.pending, state => {
                state.isFetching = true
            })
            .addCase(updateUserAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(updateUserAction.rejected, (state, action) => {
                state.isFetching = false;
            })
            .addCase(resetAvatarAction.pending, state => {
                state.isFetching = true
            })
            .addCase(resetAvatarAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(resetAvatarAction.rejected, (state, action) => {
                state.isFetching = false;
            })
    },
});

export const userAction = {
    getUserInfoAction,
    updateUserAction,
    resetAvatarAction
}

export const userSelector = (state: RootState) => state["user"];

export default userSlice.reducer;