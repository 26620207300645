import { ConfigProvider } from 'antd';
import 'assets/vendors/style';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import 'styles/scss/style.scss';
import 'styles/wieldy.less';
import App from './containers/App/index';
import { store } from './store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const NextApp = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider autoInsertSpaceInButton={false}>
        <DndProvider backend={HTML5Backend}>
          <RecoilRoot>
            <Router>
              <Switch>
                <Route path="/" component={App} />
              </Switch>
            </Router>
          </RecoilRoot>
        </DndProvider>
      </ConfigProvider>
    </QueryClientProvider>
  </Provider>
);

export default NextApp;
