import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { me } from 'store/authSlice';

import { ROUTES } from '../../../constants/RouteConst';
import { RootState, useAppDispatch } from '../../../store';
import { logout } from '../../../store/authSlice';
import { api } from '../../../util/api';
export interface Dashboard {
  id: string;
  name: string;
  description: string;
}

const ListDashboard = () => {
  const [dashboardList, setDashboardList] = useState<Dashboard[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const userInfor = useSelector((state: RootState) => state.auth.authUser);
  const history = useHistory();

  useEffect(() => {
    try {
      (async () => {
        const response = await api.get('/dashboard');
        setDashboardList(response.data.content);
      })();

      return () => {
        setDashboardList([]);
      };
    } catch (error) {
      message.error('エラーが発生しました');
    }
  }, []);

  useEffect(() => {
    try {
      if(!userInfor?.dashboardRanks) {
        dispatch(me());
      }
    } catch (error) {
      message.error('エラーが発生しました');
    }
  }, [userInfor]);

  return (
    <>
      <div id="cockpit-list">
        <div className="container">
          <h2
            className="title"
            style={{ marginBottom: dashboardList.length === 0 ? '100px' : '24px' }}
          >
            ダッシュボード一覧
          </h2>
          <div className="row">
            {dashboardList.length > 0 &&
              dashboardList.map((dashboard) => (
                <div className="col-12 col-md-4" key={dashboard.id}>
                  <div
                    className="cockpit-box"
                    onClick={() => history.push(`/dashboard/${dashboard.id}`)}
                  >
                    <Link to={`/dashboard/${dashboard.id}`}>{dashboard.name}</Link>
                    <p>{dashboard.description}</p>
                  </div>
                </div>
              ))}
          </div>
          <div className="group-btn">
            {userInfor?.rank === 2 && (
              <Link to={ROUTES.DASHBOARD_CREATE} className="btn btn-create">
                ダッシュボードを作成
              </Link>
            )}
            <button className="btn-logout" onClick={() => setIsModalVisible(true)}>
              ログアウトはこちら
            </button>
          </div>
        </div>
      </div>
      <Modal visible={isModalVisible} footer={null} closable={false} className="nav-sidebar-modal">
        <p>ログアウトしますか？</p>
        <div>
          <button className="btn btn-return" onClick={() => setIsModalVisible(false)}>
            戻る
          </button>
          <button className="btn btn-logout" onClick={() => dispatch(logout())}>
            ログアウト
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ListDashboard;
