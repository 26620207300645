import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { ROUTES } from '../../constants/RouteConst';
import App from '../../routes/index';
import { RootState } from '../../store';
import Header from '../Header';
import AppSidebar from './AppSidebar';
import { useMeProfile } from '@hooks/me';

const MainApp = () => {
  const { getMeProfile } = useMeProfile();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const { navStyle } = useSelector((state: RootState) => state.setting);
  const match = useRouteMatch();
  const location = useLocation();

  const showAll =
    location.pathname !== ROUTES.DASHBOARD_LIST && location.pathname !== ROUTES.DASHBOARD_CREATE;

  const showSidebar =
    location.pathname.includes('/change-password') ||
    location.pathname.includes('/confirm-change-password') ||
    location.pathname.includes('/invite-user');

  useEffect(() => {
    getMeProfile();
  }, []);

  return (
    <>
      <div className={`${!sidebarCollapsed ? 'open-sidebar' : ''}`}>
        <div className="wrapper">
          {showAll && (
            <>
              <Header
                sidebarCollapsed={sidebarCollapsed}
                setSidebarCollapsed={setSidebarCollapsed}
              />
              {!showSidebar && (
                <AppSidebar
                  sidebarCollapsed={sidebarCollapsed}
                  setSidebarCollapsed={setSidebarCollapsed}
                  navStyle={navStyle}
                />
              )}
            </>
          )}
        </div>
      </div>
      <App match={match} sidebarCollapsed={sidebarCollapsed} showSidebar={showSidebar} />
    </>
  );
};
export default MainApp;
