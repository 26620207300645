import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import QuestionDto, { IQuestionDto } from "mapping/Question/QuestionDto";
import { api } from "../util/api";
import { RootState } from './index';
import { removeObjectEmptyValue } from '../util/function';
import { IFilterDto } from "mapping/UtilDto";
import { faL } from "@fortawesome/free-solid-svg-icons";

interface QuestionState {
    isFetching: boolean
    item: IQuestionDto
    result: IQuestionDto[];
}

const initialState: QuestionState = {
    isFetching: false,
    item: {},
    result: [],
}

const getListAllAction = createAsyncThunk("question/getList",async (filter: {idTask: string, idMindmap?: string} & IFilterDto, { rejectWithValue }) => {
    try {
        const filterParams = removeObjectEmptyValue({
            sort: "ascend-createdOnDate",
            taskId: filter?.idTask,
            mindmapId: filter?.idMindmap
        })
        const result = await api.get(`/question`, {
            params: filterParams
        })
        return {
            ...result.data,
            content: (result.data?.content as IQuestionDto[]).map(item => new QuestionDto(item) || {})
        }
    } catch (e) {
        rejectWithValue(e);
    }
})

const addQuestionAction = createAsyncThunk("question/addQuestion", async (payload: IQuestionDto , { rejectWithValue }) => {
    try {
        const result = await api.post("/question", {
            ...payload
        })
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const addAnswerAction = createAsyncThunk("question/addAnswer", async ({id, payload} : {id: string, payload: IQuestionDto} , { rejectWithValue }) => {
    try {
        const result = await api.post("/answer", {
            questionId: id,
            ...removeObjectEmptyValue(payload)
        })
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const deleteQuestionAction = createAsyncThunk("question/deleteQuestion", async ({id} : {id ?: string} , { rejectWithValue }) => {
    try {
        const result = await api.delete(`/question/${id}`)
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const deleteAnswerAction = createAsyncThunk("question/deleteAnswer", async ({id} : {id ?: string} , { rejectWithValue }) => {
    try {
        const result = await api.delete(`/answer/${id}`)
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const updateQuestionAction = createAsyncThunk("question/update", async ({id, payload} : {id ?: string, payload: IQuestionDto} , { rejectWithValue }) => {
    try {
        const result = await api.patch(`/question/${id}`, {...payload})
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

export const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListAllAction.pending, state => {
                state.isFetching = true
            })
            .addCase(getListAllAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = (action.payload  as any)?.content;
            })
            .addCase(addQuestionAction.pending, state => {
                state.isFetching = true
            })
            .addCase(addQuestionAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(addQuestionAction.rejected, (state) => {
                state.isFetching = false;
            })
            .addCase(addAnswerAction.pending, state => {
                state.isFetching = true
            })
            .addCase(addAnswerAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(addAnswerAction.rejected, (state) => {
                state.isFetching = false;
            })
            .addCase(deleteQuestionAction.pending, state => {
                state.isFetching = true
            })
            .addCase(deleteQuestionAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(deleteQuestionAction.rejected, (state) => {
                state.isFetching = false;
            })
            .addCase(deleteAnswerAction.pending, state => {
                state.isFetching = true
            })
            .addCase(deleteAnswerAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(deleteAnswerAction.rejected, (state) => {
                state.isFetching = false;
            })
            .addCase(updateQuestionAction.pending, state => {
                state.isFetching = true
            })
            .addCase(updateQuestionAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(updateQuestionAction.rejected, (state) => {
                state.isFetching = false;
            })
    },
});

export const QuestionAction = {
    getListAllAction,
    addQuestionAction,
    addAnswerAction,
    deleteQuestionAction,
    deleteAnswerAction,
    updateQuestionAction
}

export const questionSelector = (state: RootState) => state["question"];

export default questionSlice.reducer;