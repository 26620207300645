import UtilDto, { IUtilDto } from "../UtilDto";

export interface IMindmapLinkDto extends IUtilDto {
  sourceMindmapId?: string;
  destinationMindmapId?: string;
  sourcemapPosition?: string
}

class MindmapLinkDto extends UtilDto {
  sourceMindmapId?: string;
  destinationMindmapId?: string;
  sourcemapPosition?: string
  constructor(data: IMindmapLinkDto) {
    super(data);
    this.setData(data);
  }

  setData(data: IMindmapLinkDto) {
    this.sourceMindmapId = data?.sourceMindmapId || "";
    this.destinationMindmapId = data?.destinationMindmapId || "";
    this.sourcemapPosition = data?.sourcemapPosition || ""
  }
}

export default MindmapLinkDto;
