import UtilDto, { IUtilDto } from '../UtilDto';

export interface INotifyDto extends IUtilDto {
  title?: string;
  content?: string;
  userId?: string;
  isSeen?: boolean;
  fromUser?: any;
  goto?: string;
}

class NotifyDto extends UtilDto {
  title?: string;
  content?: string;
  userId?: string;
  isSeen?: boolean;
  fromUser?: any;
  goto?: string;

  constructor(data: INotifyDto) {
    super(data);
    this.setData(data);
  }

  setData(data: INotifyDto) {
    this.title = data.title || '';
    this.content = data.content || '';
    this.userId = data.userId || '';
    this.isSeen = data.isSeen || false;
    this.fromUser = data.fromUser || {};
    this.goto = data.goto || '';
  }
}

export default NotifyDto;
