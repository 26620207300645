import antdJP from "antd/lib/locale-provider/ja_JP";
// import vnMessages from "../locales/ja_JP.json";
import common from "../locales/ja_JP/common_ja.json";
// import camera from "../locales/ja_JP/camera_ja.json";

const jaLang = {
  messages: {
    // ...vnMessages,
    ...common,
    // ...camera
  },
  antd: antdJP,
  locale: 'ja-JP',
};
export default jaLang;
