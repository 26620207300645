import antdVI from "antd/lib/locale-provider/vi_VN";
import vnMessages from "../locales/vi_VN.json";
import common from "../locales/vi_VN/common_vi.json";
import camera from "../locales/vi_VN/camera_vi.json";

const viLang = {
  messages: {
    ...vnMessages,
    ...common,
    ...camera
  },
  antd: antdVI,
  locale: 'vi-VN',
};
export default viLang;
