import { KpiMemberTargetValuesDto } from '@api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RootState } from 'store';
import { api } from 'util/api';

export interface KpiMemberTargetValueState {
  data: KpiMemberTargetValuesDto[];
  loading: boolean;
  targetValue?: KpiMemberTargetValuesDto;
}

const initialState: KpiMemberTargetValueState = {
  loading: false,
  data: [],
  targetValue: undefined,
};

export const findOne = createAsyncThunk(
  'kpiMemberTargetValue/findOne',
  async (filter: { kpiId: string; userId: string; date: string }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/kpi-member-target-values?date=${filter.date}&&userId=${filter.userId}&&kpiId=${filter.kpiId}`
      );

      return response.data;
    } catch (error) {
      const errorObj = JSON.parse((error as AxiosError).request.response);
      return rejectWithValue(errorObj);
    }
  }
);

export const changeTargetValue = createAsyncThunk(
  'kpiMemberTargetValue/change',
  async (payload: KpiMemberTargetValuesDto, { rejectWithValue }) => {
    try {
      const response = await api.post('/kpi-member-target-values/change', { ...payload });

      return response.data;
    } catch (error) {
      const errorObj = JSON.parse((error as AxiosError).request.response);
      return rejectWithValue(errorObj);
    }
  }
);

export const kpiMemberTargetValueSlice = createSlice({
  name: 'kpiMemberTargetValue',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findOne.pending, (state) => {
        state.loading = true;
      })
      .addCase(findOne.fulfilled, (state, action) => {
        state.loading = false;
        state.targetValue = action.payload;
      })
      .addCase(findOne.rejected, (state) => {
        state.loading = false;
      })
      .addCase(changeTargetValue.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeTargetValue.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(changeTargetValue.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const kpiMemberTargetValueActions = kpiMemberTargetValueSlice.actions;
export const selectTargetValue = (state: RootState) => state.kpiMemberTargetValue.targetValue;
export default kpiMemberTargetValueSlice.reducer;
