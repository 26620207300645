import React from "react";

interface ICircularProgressProps {
  className?: string
}

const CircularProgress = ({ className }: ICircularProgressProps) => <div className={`loader ${className}`}>
  <img src="/logo.svg" alt="loader" />
</div>;
export default CircularProgress;
