/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

import { IRequestOptions, IRequestConfig, getConfigs, axios } from './serviceOptions';
export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class UsersService {
  /**
   *
   */
  static userControllerGet(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update authorized user data
   */
  static userControllerUpdate(
    params: {
      /** requestBody */
      body?: UpdateUserDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userControllerCompanyUser(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RESPaginatedUserDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/company-user';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userControllerGetById(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class AuthService {
  /**
   * Login
   */
  static authControllerLogin(
    params: {
      /** requestBody */
      body?: LoginDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RESLoginDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Register
   */
  static authControllerRegister(
    params: {
      /** requestBody */
      body?: RegisterDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RESUserDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Generate password hash
   */
  static authControllerGeneratePassword(
    params: {
      /**  */
      password: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/generate-password/{password}';
      url = url.replace('{password}', params['password'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Send email from forgot password screen
   */
  static authControllerSendMailForgotPassword(
    params: {
      /** requestBody */
      body?: SendMailForgotDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/send-mail-forgot-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Recover Password
   */
  static authControllerRecoverPassword(
    params: {
      /** requestBody */
      body?: RecoverPasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/recover-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Change Password
   */
  static authControllerChangePassword(
    params: {
      /** requestBody */
      body?: ChangePasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/change-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get logged in user info
   */
  static authControllerMe(options: IRequestOptions = {}): Promise<RESUserDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CompaniesService {
  /**
   * Create a company
   */
  static companyControllerLogin(
    params: {
      /** requestBody */
      body?: CreateCompanyDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RESCompanyDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all companies
   */
  static companyControllerGet(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RESPaginatedCompanyDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a company by id
   */
  static companyControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateCompanyDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UpdateResultDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get company by id
   */
  static companyControllerGetById(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RESCompanyDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ProjectService {
  /**
   * Create a project
   */
  static projectControllerCreate(
    params: {
      /** requestBody */
      body?: CreateProjectDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Find all project by dashboard
   */
  static projectControllerFind(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      userId: string;
      /**  */
      dashboardId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project/list/{dashboard_id}';
      url = url.replace('{dashboard_id}', params['dashboardId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch'],
        userId: params['userId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Find a project by id
   */
  static projectControllerFindWithKpi(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project/detail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Find a project by kpi id
   */
  static projectControllerFindOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a project
   */
  static projectControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateProjectDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a project
   */
  static projectControllerRemove(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class DashboardService {
  /**
   * Create a dashboard
   */
  static dashboardControllerCreate(
    params: {
      /** requestBody */
      body?: CreateDashboardDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all dashboard
   */
  static dashboardControllerFindAll(
    params: {
      /** requestBody */
      body?: SearchDashboardDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get overall statistic by year
   */
  static dashboardControllerStatistics(
    params: {
      /**  */
      year: number;
      /**  */
      fromMonth: number;
      /**  */
      toMonth: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard/admin-statistics/{year}';
      url = url.replace('{year}', params['year'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromMonth: params['fromMonth'], toMonth: params['toMonth'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a dashboard by id
   */
  static dashboardControllerFindOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a dashboard by id
   */
  static dashboardControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateDashboardDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a dashboard by id
   */
  static dashboardControllerRemove(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a dashboard task checkbox by id
   */
  static dashboardControllerToggleTask(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard/toggle-task/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class DashboardTemplateService {
  /**
   * Create a dashboard template
   */
  static dashboardTemplateControllerCreate(
    params: {
      /** requestBody */
      body?: CreateDashboardTemplateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RESDashboardTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard-template';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a dashboard template
   */
  static dashboardTemplateControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateDashboardTemplateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard-template/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Find all dashboard template
   */
  static dashboardTemplateControllerFind(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      dashboardId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard-template/list/{dashboardId}';
      url = url.replace('{dashboardId}', params['dashboardId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class DashboardMemberService {
  /**
   * Add a user into a dashboard
   */
  static dashboardMemberControllerCreate(
    params: {
      /** requestBody */
      body?: CreateDashboardMemberDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RESDashboardMemberInviteDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard-member';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get list member in dashboard
   */
  static dashboardMemberControllerGetUserByDashboard(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      dashboardId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RESPaginatedDashboardMemberDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard-member/list/{dashboardId}';
      url = url.replace('{dashboardId}', params['dashboardId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update my setting in dashboard
   */
  static dashboardMemberControllerUpdateMySetting(
    params: {
      /**  */
      dashboardId: string;
      /** requestBody */
      body?: UpdateDashboardMemberDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard-member/my-setting/{dashboardId}';
      url = url.replace('{dashboardId}', params['dashboardId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get my setting in dashboard
   */
  static dashboardMemberControllerGetMySetting(
    params: {
      /**  */
      dashboardId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard-member/my-setting/{dashboardId}';
      url = url.replace('{dashboardId}', params['dashboardId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a dashboard member by id
   */
  static dashboardMemberControllerUpdateMember(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateDashboardMemberDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard-member/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a dashboard member
   */
  static dashboardMemberControllerRemove(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard-member/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class KpiService {
  /**
   * Create a kpi
   */
  static kpiControllerCreate(
    params: {
      /** requestBody */
      body?: CreateKpiDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all kpi
   */
  static kpiControllerFindAll(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get kpi by dashboard
   */
  static kpiControllerFindByDashboard(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      targetMonth?: string;
      /**  */
      dashboardId: string;
      /**  */
      fromMonth?: string;
      /**  */
      toMonth?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RESPaginatedKpiDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi/list/{dashboardId}';
      url = url.replace('{dashboardId}', params['dashboardId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch'],
        targetMonth: params['targetMonth'],
        fromMonth: params['fromMonth'],
        toMonth: params['toMonth'],
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get kpi by date
   */
  static kpiControllerClone(
    params: {
      /**  */
      dashboardId: string;
      /**  */
      date: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi/clone/{dashboardId}/{date}';
      url = url.replace('{dashboardId}', params['dashboardId'] + '');
      url = url.replace('{date}', params['date'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get kpi by date
   */
  static kpiControllerFindByDate(
    params: {
      /**  */
      dashboardId: string;
      /**  */
      date: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi/{dashboardId}/{date}';
      url = url.replace('{dashboardId}', params['dashboardId'] + '');
      url = url.replace('{date}', params['date'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a kpi
   */
  static kpiControllerFindOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a kpi
   */
  static kpiControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateKpiDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UpdateResultDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a kpi
   */
  static kpiControllerRemove(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeleteResultDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a kpi
   */
  static kpiControllerBatchUpdate(
    params: {
      /**  */
      dashboardId: string;
      /** requestBody */
      body?: BatchUpdateKpiDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UpdateResultDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi/batch-update/{dashboardId}';
      url = url.replace('{dashboardId}', params['dashboardId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class NotificationService {
  /**
   * Get authorized user notifications
   */
  static notificationControllerGet(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      dashboardId: string;
      /**  */
      type: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/notification';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch'],
        dashboardId: params['dashboardId'],
        type: params['type']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Set seen for notification
   */
  static notificationControllerUpdate(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/notification/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class KpiMemberService {
  /**
   * Upsert kpi members
   */
  static kpiMemberControllerBulk(
    params: {
      /** requestBody */
      body?: BulkKpiMemberDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<KpiMemberDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi-member/bulk';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get kpi members
   */
  static kpiMemberControllerFindAll(
    params: {
      /**  */
      kpiId: string;
      /**  */
      userId: string;
      /**  */
      fromMonth: string;
      /**  */
      toMonth: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<KpiMemberDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi-member';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { kpiId: params['kpiId'], userId: params['userId'], fromMonth: params['fromMonth'], toMonth: params['toMonth'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get kpi members month process
   */
  static kpiMemberControllerFindKpiMemberMonthProcess(
    params: {
      /**  */
      kpiId: string;
      /**  */
      month: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<KpiMemberMonthProcessDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi-member/month-process';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { kpiId: params['kpiId'], month: params['month'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get kpi members process
   */
  static kpiMemberControllerFindKpiMemberProcess(
    params: {
      /**  */
      kpiId: string;
      /**  */
      fromDate: string;
      /**  */
      toDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<KpiMemberProcessDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi-member/process';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { kpiId: params['kpiId'], fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class TaskService {
  /**
   * Get list cleared task by user id
   */
  static taskControllerGetClearedTask(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      endDate: string;
      /**  */
      startDate: string;
      /**  */
      projectId: string;
      /**  */
      statusId: string;
      /**  */
      userId: string;
      /**  */
      mainTaskId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/task/cleared-task';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch'],
        endDate: params['endDate'],
        startDate: params['startDate'],
        projectId: params['projectId'],
        statusId: params['statusId'],
        userId: params['userId'],
        mainTaskId: params['mainTaskId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get list task by project id
   */
  static taskControllerGetByProjectId(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      endDate: string;
      /**  */
      startDate: string;
      /**  */
      projectId: string;
      /**  */
      statusId: string;
      /**  */
      userId: string;
      /**  */
      mainTaskId: string;
      /**  */
      view: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/task/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch'],
        endDate: params['endDate'],
        startDate: params['startDate'],
        statusId: params['statusId'],
        userId: params['userId'],
        mainTaskId: params['mainTaskId'],
        view: params['view']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Reset all task order FOR DEVELOPMENT
   */
  static taskControllerResetOrder(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/task/reset-order';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Reset all task order FOR DEVELOPMENT
   */
  static taskControllerResetOrderProject(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/task/reset-order-project';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Create a task
   */
  static taskControllerCreate(
    params: {
      /** requestBody */
      body?: CreateTaskDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/task';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get dashboard's tasks
   */
  static taskControllerTaskList(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      endDate: string;
      /**  */
      startDate: string;
      /**  */
      projectId: string;
      /**  */
      statusId: string;
      /**  */
      userId: string;
      /**  */
      mainTaskId: string;
      /**  */
      dashboardId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/task/list/{dashboard_id}';
      url = url.replace('{dashboard_id}', params['dashboardId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch'],
        endDate: params['endDate'],
        startDate: params['startDate'],
        projectId: params['projectId'],
        statusId: params['statusId'],
        userId: params['userId'],
        mainTaskId: params['mainTaskId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update tasks order
   */
  static taskControllerBulkUpdateOrder(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: TaskOrderUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/task/order/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update tasks order in its project
   */
  static taskControllerUpdateOrderProject(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: TaskProjectOrderDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/task/order-project/{project_id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update tasks type
   */
  static taskControllerUpdateType(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: TaskTypeUpdate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/task/order-type/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Toggle clearance of a task
   */
  static taskControllerToggleClearance(
    params: {
      /**  */
      id: string;
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      endDate: string;
      /**  */
      startDate: string;
      /**  */
      projectId: string;
      /**  */
      statusId: string;
      /**  */
      userId: string;
      /**  */
      mainTaskId: string;
      /**  */
      view: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/task/toggle-clearance/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch'],
        endDate: params['endDate'],
        startDate: params['startDate'],
        projectId: params['projectId'],
        statusId: params['statusId'],
        userId: params['userId'],
        mainTaskId: params['mainTaskId'],
        view: params['view']
      };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a task
   */
  static taskControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateTaskDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/task/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a task
   */
  static taskControllerRemove(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/task/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class StatusService {
  /**
   * Create a status
   */
  static statusControllerCreate(
    params: {
      /** requestBody */
      body?: CreateStatusDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RESStatusDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/status';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all status
   */
  static statusControllerFindAll(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      dashboardId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RESPaginatedStatusDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/status/list/{dashboardId}';
      url = url.replace('{dashboardId}', params['dashboardId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a status
   */
  static statusControllerFindOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RESStatusDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/status/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a status
   */
  static statusControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateStatusDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RESStatusDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/status/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a status
   */
  static statusControllerRemove(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/status/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class MindmapService {
  /**
   * Create a mindmap
   */
  static mindmapControllerCreate(
    params: {
      /** requestBody */
      body?: CreateMindmapDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mindmap';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all mindmap
   */
  static mindmapControllerFindAll(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mindmap';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a mindmap
   */
  static mindmapControllerFindOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mindmap/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a mindmap
   */
  static mindmapControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateMindmapDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mindmap/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a mindmap
   */
  static mindmapControllerRemove(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mindmap/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get mindmap by task id
   */
  static mindmapControllerFindByTaskId(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      taskId: string;
      /**  */
      view: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mindmap/list/{taskId}';
      url = url.replace('{taskId}', params['taskId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch'],
        view: params['view']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Link a mindmap to other
   */
  static mindmapControllerLinkMindmap(
    params: {
      /** requestBody */
      body?: CreateMindmapLinkDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mindmap/link';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a mindmap link
   */
  static mindmapControllerUnlinkMindmap(
    params: {
      /**  */
      linkId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mindmap/unlink/{link_id}';
      url = url.replace('{link_id}', params['linkId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class QuestionService {
  /**
   * Create a question
   */
  static questionControllerCreate(
    params: {
      /** requestBody */
      body?: CreateQuestionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/question';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all question by task and mindmap id
   */
  static questionControllerFindAll(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      taskId: string;
      /**  */
      mindmapId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/question';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch'],
        taskId: params['taskId'],
        mindmapId: params['mindmapId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a question
   */
  static questionControllerFindOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/question/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a question
   */
  static questionControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateQuestionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/question/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a question
   */
  static questionControllerRemove(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/question/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AnswerService {
  /**
   * Post an answer
   */
  static answerControllerCreate(
    params: {
      /** requestBody */
      body?: CreateAnswerDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/answer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update an answer
   */
  static answerControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateAnswerDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/answer/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete an answer
   */
  static answerControllerRemove(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/answer/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CampaignService {
  /**
   * Create a campaign
   */
  static campaignControllerCreate(
    params: {
      /** requestBody */
      body?: CreateCampaignDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/campaign';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all campaign
   */
  static campaignControllerFindAll(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      type: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/campaign';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch'],
        type: params['type']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a campaign
   */
  static campaignControllerFindOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/campaign/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a campaign
   */
  static campaignControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateCampaignDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/campaign/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a campaign
   */
  static campaignControllerRemove(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/campaign/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class MemoService {
  /**
   * Create a memo
   */
  static memoControllerCreate(
    params: {
      /** requestBody */
      body?: CreateMemoDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/memo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all memo by task and mindmap id
   */
  static memoControllerFindAll(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      taskId: string;
      /**  */
      mindmapId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/memo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch'],
        taskId: params['taskId'],
        mindmapId: params['mindmapId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a memo
   */
  static memoControllerFindOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/memo/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a memo
   */
  static memoControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateMemoDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/memo/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a memo
   */
  static memoControllerRemove(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/memo/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class PaymentRequestService {
  /**
   * Create a payment-request
   */
  static paymentRequestControllerCreate(
    params: {
      /** requestBody */
      body?: CreatePaymentRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/payment-request';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all payment-request
   */
  static paymentRequestControllerFindAll(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/payment-request';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a payment-request
   */
  static paymentRequestControllerFindOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/payment-request/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a payment-request
   */
  static paymentRequestControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdatePaymentRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/payment-request/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a payment-request
   */
  static paymentRequestControllerRemove(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/payment-request/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class TurnoverService {
  /**
   * Create a turnover
   */
  static turnoverControllerCreate(
    params: {
      /** requestBody */
      body?: CreateTurnoverDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/turnover';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get turn overs
   */
  static turnoverControllerFindAll(
    params: {
      /**  */
      dashboardId: string;
      /**  */
      userId: string;
      /**  */
      month: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TurnOverDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/turnover';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { dashboardId: params['dashboardId'], userId: params['userId'], month: params['month'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Upsert a turnover
   */
  static turnoverControllerUpsert(
    params: {
      /** requestBody */
      body?: UpsertTurnOverDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TurnOverDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/turnover';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get turnover month value
   */
  static turnoverControllerFindTurnOVerMonthValue(
    params: {
      /**  */
      dashboardId: string;
      /**  */
      month: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TurnOverMonthValueDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/turnover/month-value';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { dashboardId: params['dashboardId'], month: params['month'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get turnover value
   */
  static turnoverControllerFindTurnOVerValue(
    params: {
      /**  */
      dashboardId: string;
      /**  */
      fromDate: string;
      /**  */
      toDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TurnOverValueDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/turnover/value';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { dashboardId: params['dashboardId'], fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class SubKpiService {
  /**
   * Create a sub-kpi
   */
  static subKpiControllerCreate(
    params: {
      /** requestBody */
      body?: CreateSubKpiDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sub-kpi';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all sub-kpi
   */
  static subKpiControllerFindAll(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sub-kpi';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Upsert list of sub-kpi
   */
  static subKpiControllerUpsert(
    params: {
      /** requestBody */
      body?: BatchUpsertSubKpi;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sub-kpi/batch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get kpi by kpi
   */
  static subKpiControllerFindByKpi(
    params: {
      /**  */
      kpiId: string;
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseSubKpiDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sub-kpi/list/{kpiId}/{userId}';
      url = url.replace('{kpiId}', params['kpiId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a sub-kpi
   */
  static subKpiControllerFindOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sub-kpi/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a sub-kpi
   */
  static subKpiControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateSubKpiDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sub-kpi/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a sub-kpi
   */
  static subKpiControllerRemove(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sub-kpi/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class UploadService {
  /**
   * Upload an avatar
   */
  static uploadControllerUploadedFile(
    params: {
      /**  */
      image: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['image']) {
        if (Object.prototype.toString.call(params['image']) === '[object Array]') {
          for (const item of params['image']) {
            data.append('image', item as any);
          }
        } else {
          data.append('image', params['image'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadControllerSeeUploadedFile(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/upload/{imgpath}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class SubKpiMemberService {
  /**
   * Create a sub-kpi
   */
  static subKpiMemberControllerCreate(
    params: {
      /** requestBody */
      body?: CreateSubKpiMemberDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sub-kpi-member';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all sub-kpi
   */
  static subKpiMemberControllerFindAll(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sub-kpi-member';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get kpi by kpi
   */
  static subKpiMemberControllerFindByKpi(
    params: {
      /**  */
      kpiId: string;
      /**  */
      date: string;
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubKpiMemberDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sub-kpi-member/list/{kpiId}/{date}/{userId}';
      url = url.replace('{kpiId}', params['kpiId'] + '');
      url = url.replace('{date}', params['date'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a sub-kpi
   */
  static subKpiMemberControllerFindOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sub-kpi-member/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update a sub-kpi
   */
  static subKpiMemberControllerUpdate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateSubKpiMemberDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sub-kpi-member/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete a sub-kpi
   */
  static subKpiMemberControllerRemove(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sub-kpi-member/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class KpiMemberTargetValuesService {
  /**
   * Change target value for user
   */
  static kpiMemberTargetValuesControllerChangeTargetValue(
    params: {
      /** requestBody */
      body?: KpiMemberTargetValuesDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<KpiMemberTargetValuesDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi-member-target-values/change';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static kpiMemberTargetValuesControllerFindOne(
    params: {
      /**  */
      page?: number;
      /**  */
      size?: number;
      /**  */
      sort?: string;
      /**  */
      fullTextSearch?: string;
      /**  */
      date: string;
      /**  */
      kpiId: string;
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kpi-member-target-values';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        size: params['size'],
        sort: params['sort'],
        fullTextSearch: params['fullTextSearch'],
        date: params['date'],
        kpiId: params['kpiId'],
        userId: params['userId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export interface RESUserDto {
  /**  */
  id: string;

  /**  */
  avatar: string;

  /**  */
  email: string;

  /**  */
  fullName: string;

  /**  */
  furigana: string;

  /**  */
  rank: number;

  /**  */
  companyId: string;

  dashboardRanks?: any;
}

export interface RESPaginatedUserDto {
  /**  */
  content: RESUserDto[];

  /**  */
  total: number;

  /**  */
  page: number;

  /**  */
  size: number;
}

export interface UpdateUserDto {
  /**  */
  fullName: string;

  /**  */
  avatar: string;
}

export interface LoginDto {
  /**  */
  email: string;

  /**  */
  password: string;
}

export interface RESLoginDto {
  /**  */
  token: string;

  /**  */
  id: string;

  /**  */
  email: string;

  /**  */
  fullName: string;

  /**  */
  rank: number;

  /**  */
  companyId: string;
}

export interface RegisterDto {
  /**  */
  email: string;

  /**  */
  password: string;

  /**  */
  fullName: string;

  /**  */
  companyId: string;
}

export interface SendMailForgotDto {
  /**  */
  email: string;
}

export interface RecoverPasswordDto {
  /**  */
  password: string;

  /**  */
  confirmPassword: string;
}

export interface ChangePasswordDto {
  /**  */
  oldPassword: string;

  /**  */
  password: string;

  /**  */
  confirmPassword: string;
}

export interface CreateCompanyDto {
  /**  */
  email: string;

  /**  */
  name: string;

  /**  */
  hp: string;

  /**  */
  phone: string;

  /**  */
  plan: number;

  /**  */
  personInChargeName: string;

  /**  */
  personSalesName: string;
}

export interface RESCompanyDto {
  /**  */
  email: string;

  /**  */
  name: string;

  /**  */
  hp: string;

  /**  */
  phone: string;

  /**  */
  plan: number;

  /**  */
  personInChargeName: string;

  /**  */
  personSalesName: string;

  /**  */
  id: string;
}

export interface UpdateCompanyDto {
  /**  */
  email?: string;

  /**  */
  name?: string;

  /**  */
  hp?: string;

  /**  */
  phone?: string;

  /**  */
  plan?: number;

  /**  */
  personInChargeName?: string;

  /**  */
  personSalesName?: string;
}

export interface UpdateResultDto {
  /**  */
  raw: object;

  /**  */
  affected: number;

  /**  */
  generatedMaps: string[];
}

export interface RESPaginatedCompanyDto {
  /**  */
  content: RESCompanyDto[];

  /**  */
  total: number;

  /**  */
  page: number;

  /**  */
  size: number;
}

export interface CreateProjectDto {
  /**  */
  kpiId: string;

  /**  */
  connectKpiId: string;

  /**  */
  userId: string;

  /**  */
  memo: string;

  dashboardId?: string;
}

export interface UpdateProjectDto {
  /**  */
  kpiId?: string;

  /**  */
  connectKpiId?: string;

  /**  */
  userId?: string;

  /**  */
  memo?: string;

  dashboardId: string;
}

export interface DashboardDto {
  /**  */
  name: string;

  /**  */
  description: string;

  /**  */
  companyId: string;
}

export interface SubKpiTargetValueDto {
  /**  */
  id?: string;

  /**  */
  targetValue: number;

  /**  */
  subKpiId: string;

  /**  */
  date: string;
}

export interface BaseSubKpiDto {
  createdOnDate: Date;
  /**  */
  id?: string;

  /**  */
  name: string;

  /**  */
  targetValue: number;

  /**  */
  alertValue: number;

  /**  */
  process: number;

  /**  */
  userId: string;

  /**  */
  kpiId: string;

  subKpiTargetValues: SubKpiTargetValueDto[];
}

export interface KpiTargetValueConversionDto {
  /**  */
  id?: string;

  /**  */
  kpiId: string;

  /**  */
  targetValueConversion: number;

  /**  */
  date: string;

  /**  */
  userId: string;
}

export interface KpiDto {
  /**  */
  id?: string;

  /**  */
  name: string;

  /**  */
  targetMonth: Date;

  /**  */
  targetValue?: number;

  /**  */
  alertValue: number;

  /**  */
  unit: string;

  /**  */
  formula: string;

  /**  */
  dashboardId?: string;

  /**  */
  subKpis?: BaseSubKpiDto[];

  kpiMemberTargetValues?: KpiMemberTargetValuesDto[];

  kpiMembers?: KpiMemberDto[];

  targetValueConversion?: number;

  kpiTargetValueConversions: KpiTargetValueConversionDto[];

  isAddKpi?: boolean;

  createdOnDate?: Date;
}

export interface CreateDashboardDto {
  /**  */
  dashboard: DashboardDto;

  /**  */
  kpis: KpiDto[];

  /**  */
  members: RESUserDto[];
}

export interface RESDashboardDto {
  /**  */
  name: string;

  /**  */
  description: string;

  /**  */
  companyId: string;

  /**  */
  id: string;

  company?: RESCompanyDto;

  createdOnDate?: Date;
}

export interface SearchDashboardDto {
  /**  */
  page?: number;

  /**  */
  size?: number;

  /**  */
  sort?: string;

  /**  */
  fullTextSearch?: string;

  /**  */
  name: string;

  /**  */
  description: string;
}

export interface RESPaginatedDashboardDto {
  /**  */
  content: RESDashboardDto[];

  /**  */
  total: number;

  /**  */
  page: number;

  /**  */
  size: number;
}

export interface DashStatTotal {
  /**  */
  users: number;

  /**  */
  companies: number;

  /**  */
  sales: number;
}

export interface DashStat {
  /**  */
  month: number;

  /**  */
  totalUser: number;

  /**  */
  totalCompany: number;

  /**  */
  totalSale: number;
}

export interface RESDashboardStatistics {
  /**  */
  total: DashStatTotal;

  /**  */
  statistics: DashStat[];
}

export interface RESStatusDto {
  /**  */
  name: string;

  /**  */
  colorCode: string;

  /**  */
  dashboardId: string;

  /**  */
  id: string;
}

export interface RESProjectDto {
  /**  */
  kpiId: string;

  /**  */
  connectKpiId: string;

  /**  */
  userId: string;

  /**  */
  memo: string;

  /**  */
  id: string;
}

export interface SelectDashboardDto {
  /**  */
  dashboard: DashboardDto;

  /**  */
  kpis: KpiDto[];

  /**  */
  members: RESUserDto[];

  /**  */
  statuses: RESStatusDto[];

  /**  */
  projects: RESProjectDto[];
}

export interface UpdateDashboardDto {
  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface DeleteResultDto {
  /**  */
  raw: object;

  /**  */
  affected: number;
}

export interface CreateDashboardTemplateDto {
  /**  */
  title: string;

  /**  */
  note: string;

  /**  */
  sampleMessage: string;

  /**  */
  sampleVideo: string;

  /**  */
  dashboardId: string;
}

export interface RESDashboardTemplateDto {
  /**  */
  id?: string;

  /**  */
  title: string;

  /**  */
  note?: string;

  /**  */
  sampleMessage?: string;

  /**  */
  sampleVideo?: string;

  /**  */
  sampleImage?: string;

  /**  */
  dashboardId: string;

  /**  */
  createdBy?: RESUserDto;

  /**  */
  createdOnDate?: Date;

  user?: RESUserDto;

  isTaskCreated?: boolean;
}

export interface UpdateDashboardTemplateDto {
  /**  */
  title?: string;

  /**  */
  note?: string;

  /**  */
  sampleMessage?: string;

  /**  */
  sampleVideo?: string;

  /**  */
  sampleImage: string;
}

export interface RESPaginatedDashboardTemplateDto {
  /**  */
  content: RESDashboardTemplateDto[];

  /**  */
  total: number;

  /**  */
  page: number;

  /**  */
  size: number;
}

export interface CreateDashboardMemberDto {
  /**  */
  email: string;

  /**  */
  dashboardId: string;

  /**  */
  message: string;
}

export interface MemberDto {
  /**  */
  id: string;

  /**  */
  enableMailNoti: boolean;

  /**  */
  enableNotiMemberNotUpdateStatus: boolean;

  /**  */
  enableMailNotiByComment: boolean;

  /**  */
  enableNotiMemberExpiredTask: boolean;

  /**  */
  enableNotiOutOfTask: boolean;

  /**  */
  enableMailNotiChangeKpi: boolean;

  /**  */
  timeNotiMemberNotUpdateStatus?: number;

  /**  */
  userId: string;

  /**  */
  user?: RESUserDto;

  /**  */
  dashboardId: string;

  /**  */
  rank: number;

  createdOnDate?: Date | string;
}

export interface MemberErrors {
  /**  */
  ALREADY_IN: string[];
}

export interface RESDashboardMemberInviteDto {
  /**  */
  content: MemberDto[];

  /**  */
  errors: MemberErrors;
}

export interface RESPaginatedDashboardMemberDto {
  /**  */
  content: MemberDto[];

  /**  */
  total: number;

  /**  */
  page: number;

  /**  */
  size: number;
}

export interface UpdateDashboardMemberDto {
  /**  */
  enableMailNoti?: boolean;

  /**  */
  enableNotiMemberNotUpdateStatus?: boolean;

  /**  */
  enableMailNotiByComment?: boolean;

  /**  */
  enableNotiMemberExpiredTask?: boolean;

  /**  */
  enableNotiOutOfTask?: boolean;

  /**  */
  enableMailNotiChangeKpi?: boolean;

  /**  */
  timeNotiMemberNotUpdateStatus?: number;

  /**  */
  rank?: number;
}

export interface CreateKpiDto {
  /**  */
  id?: string;

  /**  */
  name: string;

  /**  */
  targetMonth: Date;

  /**  */
  targetValue: number;

  /**  */
  alertValue: number;

  /**  */
  unit: string;

  /**  */
  formula: string;

  /**  */
  dashboardId?: string;

  /**  */
  subKpis?: BaseSubKpiDto[];
}

export interface RESPaginatedKpiDto {
  /**  */
  content: KpiDto[];

  /**  */
  total: number;

  /**  */
  page: number;

  /**  */
  size: number;
}

export interface BatchUpdateKpiDto {
  /**  */
  month: string;

  /**  */
  batch: KpiDto[];
}

export interface UpdateKpiDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  targetMonth?: Date;

  /**  */
  targetValue?: number;

  /**  */
  alertValue?: number;

  /**  */
  unit?: string;

  /**  */
  formula?: string;

  /**  */
  dashboardId?: string;

  /**  */
  subKpis?: BaseSubKpiDto[];
}

export interface UpsertKpiMemberDto {
  /**  */
  kpiId: string;

  /**  */
  userId: string;

  /**  */
  process: number;

  /**  */
  date: Date;
}

export interface BulkKpiMemberDto {
  /**  */
  kpiMembers: UpsertKpiMemberDto[];

  dashboardId: string;
}

export interface KpiMemberDto {
  /**  */
  id: string;

  /**  */
  kpiId: string;

  /**  */
  userId: string;

  /**  */
  process: number;

  /**  */
  date: Date;
}

export interface KpiMemberMonthProcessDto {
  /**  */
  userId: string;

  /**  */
  kpiId: string;

  /**  */
  percentage: number;

  /**  */
  process: number;
}

export interface KpiMemberProcessDto {
  /**  */
  userId: string;

  /**  */
  kpiId: string;

  /**  */
  percentage: number;

  /**  */
  process: number;
}

export interface CreateTaskDto {
  /**  */
  type: EnumCreateTaskDtoType;

  /**  */
  title: string;

  /**  */
  content: string;

  /**  */
  colorCode: string;

  /**  */
  endDate: string;

  /**  */
  startDate: string;

  /**  */
  order: number;

  /**  */
  projectId: string;

  /**  */
  statusId: string;

  /**  */
  userId: string;

  /**  */
  dashboardId: string;

  /**  */
  mainTaskId: string;
}

export interface TaskOrderUpdateDto {
  /**  */
  newStatusId: string;

  /**  */
  newNextOrder: number;
}

export interface TaskProjectOrderUpdate {
  /**  */
  id: string;

  /**  */
  projectOrder: number;
}

export interface TaskProjectOrderDto {
  /**  */
  newOrders: TaskProjectOrderUpdate[];
}

export interface TaskTypeUpdate {
  /**  */
  id: string;
}

export interface UpdateTaskDto {
  /**  */
  type?: EnumUpdateTaskDtoType;

  /**  */
  title?: string;

  /**  */
  content?: string;

  /**  */
  colorCode?: string;

  /**  */
  endDate?: string;

  /**  */
  startDate?: string;

  /**  */
  order?: number;

  /**  */
  projectId?: string;

  /**  */
  statusId?: string;

  /**  */
  userId?: string;

  /**  */
  dashboardId?: string;

  /**  */
  mainTaskId?: string;
}

export interface CreateStatusDto {
  /**  */
  name: string;

  /**  */
  colorCode: string;

  /**  */
  dashboardId: string;
}

export interface RESPaginatedStatusDto {
  /**  */
  content: RESStatusDto[];

  /**  */
  total: number;

  /**  */
  page: number;

  /**  */
  size: number;
}

export interface UpdateStatusDto {
  /**  */
  name?: string;

  /**  */
  colorCode?: string;

  /**  */
  dashboardId?: string;
}

export interface CreateMindmapDto {
  /**  */
  content: string;

  /**  */
  type: string;

  /**  */
  colorCode: string;

  /**  */
  order: number;

  /**  */
  parentId: string;

  /**  */
  taskId: string;

  /**  */
  createdOnDate?: Date;

  /**  */
  aboveMindmapId: string;
}

export interface UpdateMindmapDto {
  /**  */
  content?: string;

  /**  */
  type?: string;

  /**  */
  colorCode?: string;

  /**  */
  order?: number;

  /**  */
  parentId?: string;

  /**  */
  taskId?: string;

  /**  */
  createdOnDate?: Date;

  /**  */
  aboveMindmapId?: string;

  isHideMindMap?: boolean;

  prevMindmapId?: string;
}

export interface CreateMindmapLinkDto {
  /**  */
  sourceMindmapId: string;

  /**  */
  destinationMindmapId: string;

  /**  */
  sourcemapPosition: string;
}

export interface CreateQuestionDto {
  /**  */
  content: string;

  /**  */
  image: string;

  /**  */
  taskId: string;

  /**  */
  mindmapId: string;
}

export interface UpdateQuestionDto {
  /**  */
  content?: string;

  /**  */
  image?: string;

  /**  */
  taskId?: string;

  /**  */
  mindmapId?: string;
}

export interface CreateAnswerDto {
  /**  */
  content: string;

  /**  */
  image: string;

  /**  */
  createdOnDate: Date;

  /**  */
  questionId: string;

  /**  */
  userId: string;
}

export interface UpdateAnswerDto {
  /**  */
  content?: string;

  /**  */
  image?: string;

  /**  */
  createdOnDate?: Date;

  /**  */
  questionId?: string;

  /**  */
  userId?: string;
}

export interface CreateCampaignDto {
  /**  */
  title: string;

  /**  */
  detail: string;

  /**  */
  startDate: Date;

  /**  */
  endDate: Date;

  /**  */
  discountRate: number;
}

export interface UpdateCampaignDto {
  /**  */
  title?: string;

  /**  */
  detail?: string;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  discountRate?: number;
}

export interface CreateMemoDto {
  /**  */
  content: string;

  /**  */
  image: string;

  /**  */
  taskId: string;

  /**  */
  mindmapId: string;
}

export interface UpdateMemoDto {
  /**  */
  content?: string;

  /**  */
  image?: string;

  /**  */
  taskId?: string;

  /**  */
  mindmapId?: string;
}

export interface CreatePaymentRequestDto {
  /**  */
  amount: number;

  /**  */
  requestAt: Date;

  /**  */
  companyId: string;
}

export interface UpdatePaymentRequestDto {
  /**  */
  amount?: number;

  /**  */
  requestAt?: Date;

  /**  */
  companyId?: string;
}

export interface CreateTurnoverDto {
  /**  */
  value: number;

  /**  */
  date: Date;

  /**  */
  dashboardId: string;

  /**  */
  userId: string;
}

export interface TurnOverDto {
  /**  */
  id: string;

  /**  */
  dashboardId: string;

  /**  */
  userId: string;

  /**  */
  value: number;

  /**  */
  date: Date;
}

export interface UpsertTurnOverDto {
  /**  */
  dashboardId: string;

  /**  */
  userId: string;

  /**  */
  value: number;

  /**  */
  date: Date;
}

export interface TurnOverMonthValueDto {
  /**  */
  userId: string;

  /**  */
  dashboardId: string;

  /**  */
  percentage: number;

  /**  */
  value: number;
}

export interface TurnOverValueDto {
  /**  */
  userId: string;

  /**  */
  dashboardId: string;

  /**  */
  percentage: number;

  /**  */
  value: number;
}

export interface CreateSubKpiDto {
  /**  */
  id?: string;

  /**  */
  name: string;

  /**  */
  targetValue: number;

  /**  */
  alertValue: number;

  /**  */
  process: number;

  /**  */
  userId: string;

  /**  */
  kpiId: string;
}

export interface LiteSubKpiDto {
  /**  */
  id?: string;

  /**  */
  name: string;

  /**  */
  targetValue: number;

  /**  */
  kpiId: string;

  /**  */
  userId: string;
}

export interface BatchUpsertSubKpi {
  /**  */
  batch: LiteSubKpiDto[];
}

export interface UpdateSubKpiDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  targetValue?: number;

  /**  */
  alertValue?: number;

  /**  */
  process?: number;

  /**  */
  userId?: string;

  /**  */
  kpiId?: string;
}

export interface CreateSubKpiMemberDto {
  /**  */
  id?: string;

  /**  */
  subKpiId: string;

  /**  */
  userId?: string;

  /**  */
  process: number;

  /**  */
  date: Date;
}

export interface SubKpiMemberDto {
  /**  */
  id?: string;

  /**  */
  subKpiId: string;

  /**  */
  userId?: string;

  /**  */
  process: number;

  /**  */
  date: Date;
}

export interface UpdateSubKpiMemberDto {
  /**  */
  id?: string;

  /**  */
  subKpiId?: string;

  /**  */
  userId?: string;

  /**  */
  process?: number;

  /**  */
  date?: Date;
}

export interface KpiMemberTargetValuesDto {
  /**  */
  id?: string;

  /**  */
  kpiId: string;

  /**  */
  userId: string;

  /**  */
  targetValue: number;

  /**  */
  date: string;
}
export enum EnumCreateTaskDtoType {
  'main' = 'main',
  'sub' = 'sub'
}
export enum EnumUpdateTaskDtoType {
  'main' = 'main',
  'sub' = 'sub'
}
