import clsx from 'clsx';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../store';
import IntlMessages from '../../util/IntlMessages';
import ROUTES_SIDEBAR from './routes';
import { Modal } from 'antd';
import { logout } from 'store/authSlice';
import { ROUTES } from 'constants/RouteConst';

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed, params }) => {
  const userInfor = useSelector((state: RootState) => state.auth.authUser);
  const dashboardMatch = useRouteMatch('/dashboard/:dashboardId');
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const setLiActive = (route: any) => {
    let routePath = route.path.substring(route.path.lastIndexOf('/'));
    const count = location.pathname.match(/\//g)?.length;
    if (count === 2 && routePath === '/:dashboardId') {
      routePath = routePath.replace(
        '/:dashboardId',
        `/${(dashboardMatch?.params as any)?.dashboardId}`
      );
    } else {
      routePath = route.path.substring(route.path.lastIndexOf('/'));
    }

    const checkPath = location.pathname.includes(routePath);

    if (checkPath) {
      return 'active';
    }

    return '';
  };

  return (
    <section className="nav-sidebar">
      <div
        onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
        className={clsx(
          sidebarCollapsed ? 'js-open-sidebar' : 'close-sidebar',
          'cursor-pointer sidebar-button'
        )}
      >
        {sidebarCollapsed ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5Z"
              fill="#FAF9F8"
            />
            <path
              d="M20 11H4C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11Z"
              fill="#FAF9F8"
            />
            <path
              d="M20 17H4C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18C21 17.4477 20.5523 17 20 17Z"
              fill="#FAF9F8"
            />
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.6899 5.32071C18.5917 5.22227 18.475 5.14417 18.3465 5.09089C18.2181 5.0376 18.0803 5.01017 17.9413 5.01017C17.8022 5.01017 17.6645 5.0376 17.536 5.09089C17.4076 5.14417 17.2909 5.22227 17.1926 5.32071L12 10.5027L6.80736 5.31009C6.70905 5.21178 6.59233 5.1338 6.46388 5.08059C6.33543 5.02738 6.19776 5 6.05873 5C5.91969 5 5.78202 5.02738 5.65357 5.08059C5.52512 5.1338 5.40841 5.21178 5.31009 5.31009C5.21178 5.40841 5.1338 5.52512 5.08059 5.65357C5.02738 5.78202 5 5.91969 5 6.05873C5 6.19776 5.02738 6.33543 5.08059 6.46388C5.1338 6.59233 5.21178 6.70905 5.31009 6.80736L10.5027 12L5.31009 17.1926C5.21178 17.291 5.1338 17.4077 5.08059 17.5361C5.02738 17.6646 5 17.8022 5 17.9413C5 18.0803 5.02738 18.218 5.08059 18.3464C5.1338 18.4749 5.21178 18.5916 5.31009 18.6899C5.40841 18.7882 5.52512 18.8662 5.65357 18.9194C5.78202 18.9726 5.91969 19 6.05873 19C6.19776 19 6.33543 18.9726 6.46388 18.9194C6.59233 18.8662 6.70905 18.7882 6.80736 18.6899L12 13.4973L17.1926 18.6899C17.291 18.7882 17.4077 18.8662 17.5361 18.9194C17.6646 18.9726 17.8022 19 17.9413 19C18.0803 19 18.218 18.9726 18.3464 18.9194C18.4749 18.8662 18.5916 18.7882 18.6899 18.6899C18.7882 18.5916 18.8662 18.4749 18.9194 18.3464C18.9726 18.218 19 18.0803 19 17.9413C19 17.8022 18.9726 17.6646 18.9194 17.5361C18.8662 17.4077 18.7882 17.291 18.6899 17.1926L13.4973 12L18.6899 6.80736C19.0934 6.40384 19.0934 5.72423 18.6899 5.32071V5.32071Z"
              fill="white"
            />
          </svg>
        )}
      </div>
      <ul className={clsx('gx-p-0', sidebarCollapsed && 'gx-d-none')}>
        {userInfor?.rank === 1
          ? ROUTES_SIDEBAR.routesManagement.map((route, index) => (
              <li key={index} className={location.pathname === route.path ? 'active' : ''}>
                <Link to={route.path}>
                  <span className="icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {route.icon}
                    </svg>
                  </span>
                  <span>
                    <IntlMessages id={`sidebar.management.${route.name}`} />
                  </span>
                </Link>
              </li>
            ))
          : ROUTES_SIDEBAR.routes.map((route, index) => {
              if (location.pathname.includes('/edit') || location.pathname.includes('/detail')) {
                return (
                  <li key={index} className={route.path === ROUTES.DASHBOARD ? 'active' : ''}>
                    <Link
                      to={route.path.replace(
                        ':dashboardId',
                        (dashboardMatch?.params as any)?.dashboardId || ''
                      )}
                    >
                      <span className="icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {route.icon}
                        </svg>
                      </span>
                      <span>
                        <IntlMessages id={`sidebar.${route.name}`} />
                      </span>
                    </Link>
                  </li>
                );
              } else {
                return (
                  <li key={index} className={setLiActive(route)}>
                    <Link
                      to={route.path.replace(
                        ':dashboardId',
                        (dashboardMatch?.params as any)?.dashboardId || ''
                      )}
                    >
                      <span className="icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {route.icon}
                        </svg>
                      </span>
                      <span>
                        <IntlMessages id={`sidebar.${route.name}`} />
                      </span>
                    </Link>
                  </li>
                );
              }
            })}
        <li>
          <button onClick={() => setIsModalVisible(true)}>
            <span className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 4L13.59 5.41L16.17 8H6V10H16.17L13.59 12.58L15 14L20 9L15 4ZM2 2H10V0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H10V16H2V2Z"
                  fill="white"
                />
              </svg>
            </span>
            <span>
              <IntlMessages id="sidebar.logout" />
            </span>
          </button>
        </li>
      </ul>
      <Modal visible={isModalVisible} footer={null} closable={false} className="nav-sidebar-modal">
        <p>ログアウトしますか？</p>
        <div>
          <button className="btn btn-return" onClick={() => setIsModalVisible(false)}>
            戻る
          </button>
          <button className="btn btn-logout" onClick={() => dispatch(logout())}>
            ログアウト
          </button>
        </div>
      </Modal>
    </section>
  );
};

export default React.memo(SidebarContent);
