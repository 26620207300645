import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import authReducer from './authSlice';
import settingReducer from './settingSlice';
import projectReducer from './projectSlice';
import dashboardReducer from "./dashboard";
import kpiReducer from "./kpiSlice";
import taskReducer from './taskSlice';
import dashboardDetailReducer from './dashboardDetailSlice';
import statusReducer from './statusSlice';
import dashboardMemberReducer from './dashboardMemberSlice';
import userReducer from './userSlice';
import mindmapReducer from './mindmapSlice';
import questionReducer from './questionSlice';
import memoReducer from './memoSlice';
import notifyReducer from './notifySlice';
import subKPIReducer from './subKPISlice';
import kpiMemberTargetValueReducer from './kpiMemberTargetValueSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    setting: settingReducer,
    project: projectReducer,
    task: taskReducer,
    dashboard: dashboardReducer,
    kpi: kpiReducer,
    dashboardDetail: dashboardDetailReducer,
    status: statusReducer,
    dashboardMember: dashboardMemberReducer,
    mindmap: mindmapReducer,
    question: questionReducer,
    user: userReducer,
    memo: memoReducer,
    notify: notifyReducer,
    subKPI: subKPIReducer,
    kpiMemberTargetValue: kpiMemberTargetValueReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>();
