import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NotifyDto, { INotifyDto } from "../mapping/Notify/NotifyDto";
import { api } from "../util/api";
import { RootState } from './index';

interface NotifyState {
    isFetching: boolean
    item: INotifyDto
    result: INotifyDto[],
    total: number,
}

const initialState: NotifyState = {
    isFetching: false,
    item: {},
    result: [],
    total: 0,
}

const getListAllAction = createAsyncThunk("notify/getList", async ({id, type, page, size} : {id?: string, type?: string, page: number, size: number}, { rejectWithValue }) => {
    try {
        const result = await api.get(type ? `/notification?dashboardId=${id}&type=${type}&page=${page}&size=${size}` : `/notification?dashboardId=${id}&page=${page}&size=${size}`)
        return {
            ...result.data,
            content: (result.data?.content as INotifyDto[]).map(item => new NotifyDto(item) || {})
        }
    } catch (e) {
        rejectWithValue(e);
    }
})

const updateNotifyAction = createAsyncThunk("notify/updateNotify", async ({id} : {id?: string} , { rejectWithValue }) => {
    try {
        const result = await api.patch(`/notification/${id}`)
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

export const notifySlice = createSlice({
    name: 'notify',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListAllAction.pending, state => {
                state.isFetching = true
            })
            .addCase(getListAllAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = (action.payload  as any)?.content;
                state.total = (action.payload  as any)?.total;
            })
            .addCase(updateNotifyAction.pending, state => {
                state.isFetching = true
            })
            .addCase(updateNotifyAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(updateNotifyAction.rejected, (state) => {
                state.isFetching = false;
            })
    },
});

export const notifyAction = {
    getListAllAction,
    updateNotifyAction
}

export const notifySelector = (state: RootState) => state["notify"];

export default notifySlice.reducer;
