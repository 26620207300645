import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RootState } from '.';
import { STORAGES } from '../constants/StorageConst';
import { api } from '../util/api';
import { IUserDto } from '../mapping/User/UserDto';
import UserDto from '../mapping/User/UserDto';
import { Company } from 'routes/Management/Company/ListCompany';

export interface AuthPayload {
  email: string;
  password: string;
  fullName?: string;
  companyId?: string;
  avatar?: string;
  firstName?: string;
  lastName?: string;
}

export interface AuthState {
  loading: boolean;
  authUser?: IUserDto;
  loginError?: string;
  registerError?: string;
  storeDataForm?: AuthPayload;
}
export interface AuthUser {
  id: string;
  email: string;
  fullName?: string;
  rank: number;
  companyId: string;
  avatar?: string;
  company?: Company;
}

const initialState: AuthState = {
  loading: false,
  authUser: undefined || JSON.parse(localStorage.getItem('user') as string),
  loginError: '',
  registerError: '',
  storeDataForm: undefined,
};

export const login = createAsyncThunk('auth/login', async (payload: AuthPayload) => {
  const response = await api.post('auth/login', {
    ...payload,
  });
  return response.data;
});

export const register = createAsyncThunk('auth/register', async (payload: AuthPayload) => {
  const response = await api.post('auth/register', {
    ...payload,
  });
  return response.data;
});

export const me = createAsyncThunk('auth/me', async (_, { fulfillWithValue, rejectWithValue }) => {
  const token = localStorage.getItem(STORAGES.TOKEN);
  try {
    const response = await api.get('auth/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return new UserDto(response.data as IUserDto);
  } catch (error) {
    const errorObj = JSON.parse((error as AxiosError).request.response);
    return rejectWithValue(errorObj);
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.authUser = undefined;
      localStorage.removeItem(STORAGES.TOKEN);
      localStorage.removeItem(STORAGES.USER);
    },
    setDefaultStoreDataForm: (state) => {
      state.storeDataForm = undefined;
    },
    setDefaultError: (state) => {
      state.loginError = '';
      state.registerError = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        const payload = action.payload;
        const user: AuthUser = {
          id: payload.id,
          email: payload.email,
          rank: payload.rank,
          companyId: payload.companyId,
        };
        state.loading = false;
        state.authUser = user;
        state.registerError = '';
        state.storeDataForm = undefined;
        localStorage.setItem(STORAGES.TOKEN, payload.token);
        localStorage.setItem(STORAGES.USER, JSON.stringify(user));
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.storeDataForm = action.meta.arg;
        if (action.error.message === 'Request failed with status code 400') {
          state.registerError = 'メールアドレスが既に登録されています';
        } else if (action.error.message === 'Request failed with status code 404') {
          state.registerError = '存在しない会社IDです';
        } else {
          state.registerError = '内部サーバーエラー';
        }
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        const payload = action.payload;
        const user: AuthUser = {
          id: payload.id,
          email: payload.email,
          rank: payload.rank,
          companyId: payload.companyId,
        };
        state.loading = false;
        state.authUser = user;
        state.loginError = '';
        state.storeDataForm = undefined;
        localStorage.setItem(STORAGES.TOKEN, payload.token);
        localStorage.setItem(STORAGES.USER, JSON.stringify(user));
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.storeDataForm = action.meta.arg;
        if (action.error.message === 'Request failed with status code 401') {
          state.loginError = 'メールアドレスまたはパスワードが正しくありません';
        } else {
          state.loginError = '内部サーバーエラー';
        }
      })
      .addCase(me.pending, (state) => {
        state.loading = true;
      })
      .addCase(me.fulfilled, (state, action) => {
        state.loading = false;
        state.authUser = action.payload;
        localStorage.setItem(STORAGES.USER, JSON.stringify(action.payload));
      })
      .addCase(me.rejected, (state, action) => {
        state.loading = false;
        state.authUser = undefined;
        localStorage.clear();
      });
  },
});

export const { logout, setDefaultStoreDataForm, setDefaultError } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
