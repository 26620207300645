import { RESUserDto } from "@api";
import { IQuestionDto } from "mapping/Question/QuestionDto";
import UtilDto, { IUtilDto } from "../UtilDto";

export interface IMindmapCommentDto {
  enableNotiComment?: boolean;
  userId?: string;
  mindmapId?: string;
  mindmap?: IMindmapDto;
  user?: RESUserDto;
}

export interface IMindmapDto extends IUtilDto {
  content?: string;
  colorCode?: string;
  taskId?: string;
  type?: string,
  parentId?: string,
  children?: IMindmapDto[]
  aboveMindmapId?: string;
  level?: number;
  order?: number;
  isHideMindMap?: boolean;
  prevMindmapId?: string;
  mindmapComments?: IMindmapCommentDto[];
  statusId?: string;
  questions?: IQuestionDto[];
}

class MindmapDto extends UtilDto {
  content?: string;
  colorCode?: string;
  taskId?: string;
  type?: string;
  parentId?: string
  children?: IMindmapDto[]
  aboveMindmapId?: string;
  isHideMindMap?: boolean;
  level?: number = 0;
  order?: number = 0;
  mindmapComments?: IMindmapCommentDto[];
  statusId?: string;
  questions?: IQuestionDto[];

  constructor(data: IMindmapDto) {
    super(data);
    this.setData(data);
  }

  setData(data: IMindmapDto) {
    this.content = data.content || "";
    this.colorCode = data.colorCode || "#cccccc";
    this.taskId = data.taskId || "";
    this.type = data?.type || "main"
    this.parentId = data?.parentId || "";
    this.children = data?.children || [];
    this.aboveMindmapId = data?.aboveMindmapId || "";
    this.isHideMindMap = data.isHideMindMap;
    this.mindmapComments = data.mindmapComments;
    this.level = data.level;
    this.order = data.order;
    this.statusId = data.statusId;
    this.questions = data.questions;
  }
}

export default MindmapDto;