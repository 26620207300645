import { Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { message } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../constants/RouteConst';
import { api } from '../../../util/api';
import Logo from '../../../assets/images/logo.png';

const FormItem = Form.Item;

const RecoverPassword = () => {
  const [form] = Form.useForm();
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/auth/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    })();
    return () => {
      setUser(null);
    };
  }, [token]);

  const onFinish = async (values) => {
    try {
      const response = await api.post(
        'auth/recover-password',
        { ...values },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      history.push({
        pathname: ROUTES.CONFIRM_RECOVER_PASSWORD,
        state: { data: response.data }
      });
    } catch (error) {
      message.error('エラーが発生しました');
    }
  };

  const validatePassword = (_: any, value: string) => {
    if (!value) {
      return Promise.reject('パスワードを入力してください');
    } else if (value.length < 8) {
      return Promise.reject('半角英数8文字以上で入力して下さい');
    } else {
      return Promise.resolve();
    }
  };

  const validateConfirmPassword = (_: any, value: string) => {
    if (!value) {
      return Promise.reject('パスワードを入力してください');
    }
    if (value.length < 8) {
      return Promise.reject('半角英数8文字以上で入力して下さい');
    }

    if (!value || form.getFieldValue('password') !== value) {
      return Promise.reject(new Error('パスワードが一致しません'));
    }

    return Promise.resolve();
  };

  return (
    <div id="authentication" className="page-reset-password">
      <div className="container">
        {user && user.isForgotPassword !== false ? (
          <div className="sign-form">
            <div className="image">
              <img src={Logo} alt="logo" />
            </div>
            <div className="title">
              <h3>パスワード再設定</h3>
            </div>
            <p className="desc">
              新しいパスワードを入力し、 <br />
              「パスワードを再設定」ボタンをクリックしてください。
            </p>
            <Form name="forgotPassword" onFinish={onFinish} form={form}>
              <FormItem className="form-group">
                <label>新しいパスワード</label>
                <FormItem name="password" rules={[{ validator: validatePassword }]}>
                  <Input.Password
                    placeholder="パスワード"
                    className="form-control"
                    visibilityToggle={false}
                  />
                </FormItem>
              </FormItem>
              <FormItem className="form-group">
                <label>新しいパスワード（再）</label>
                <FormItem name="confirmPassword" rules={[{ validator: validateConfirmPassword }]}>
                  <Input.Password
                    placeholder="パスワードを際入力"
                    className="form-control"
                    visibilityToggle={false}
                  />
                </FormItem>
              </FormItem>
              <FormItem>
                <button className="btn btn-submit" type="submit">
                  パスワード再設定
                </button>
              </FormItem>
              <Link to={ROUTES.SIGN_IN} className="has-account d-block">
                すでにアカウントをお持ちの方はこちら
              </Link>
            </Form>
          </div>
        ) : (
          <div className="email-expired">
            <h3>認証に失敗しました</h3>
            <p>認証メールの有効期限が切れている可能性があります。</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecoverPassword;
