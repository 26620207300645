import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MemoDto, { IMemoDto } from "mapping/Memo/MemoDto";
import { api } from "../util/api";
import { RootState } from './index';
import { removeObjectEmptyValue } from '../util/function';
import { IFilterDto } from '../mapping/UtilDto';

interface MemoState {
    isFetching: boolean
    item: IMemoDto
    result: IMemoDto[]
}

const initialState: MemoState = {
    isFetching: false,
    item: {},
    result: []
}

const getListAllAction = createAsyncThunk("memo/getList", async (filter: {idTask: string, idMindmap?: string} & IFilterDto, { rejectWithValue }) => {
    try {
        const filterParams = removeObjectEmptyValue({
            sort: "ascend-createdOnDate",
            taskId: filter?.idTask,
            mindmapId: filter?.idMindmap
        })
        const result = await api.get(`/memo`, {
            params: filterParams
        })
        return {
            ...result.data,
            content: (result.data?.content as IMemoDto[]).map(item => new MemoDto(item) || {})
        }
    } catch (e) {
        rejectWithValue(e);
    }
})

const addMemoAction = createAsyncThunk("memo/addMemo", async (payload: IMemoDto, { rejectWithValue }) => {
    try {
        const result = await api.post("/memo", {
            ...payload
        })
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const deleteMemoAction = createAsyncThunk("memo/deleteMemo", async ({ id }: { id?: string }, { rejectWithValue }) => {
    try {
        const result = await api.delete(`/memo/${id}`);
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

export const memoSlice = createSlice({
    name: 'memo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListAllAction.pending, state => {
                state.isFetching = true
            })
            .addCase(getListAllAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = (action.payload as any)?.content;
            })
            .addCase(addMemoAction.pending, state => {
                state.isFetching = true
            })
            .addCase(addMemoAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(addMemoAction.rejected, (state) => {
                state.isFetching = false;
            })
            .addCase(deleteMemoAction.pending, state => {
                state.isFetching = true
            })
            .addCase(deleteMemoAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(deleteMemoAction.rejected, (state) => {
                state.isFetching = false;
            })
    },
});

export const memoAction = {
    getListAllAction,
    addMemoAction,
    deleteMemoAction
}

export const memoSelector = (state: RootState) => state["memo"];

export default memoSlice.reducer;