import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { String } from 'lodash';
import TaskDto, { ITaskboardDto, ITaskDto } from 'mapping/Task/ModelTaskDto';
import { api } from '../util/api';
import { RootState } from './index';
import { IMindmapLinkDto } from '../mapping/MindmapLink/MindmapLinkDto';

interface TaskState {
  isFetching: boolean;
  item: ITaskDto;
  result: ITaskDto[];
  taskListResult: ITaskDto[];
  page: number;
  size: number;
  links: IMindmapLinkDto[];
}

const initialState: TaskState = {
  isFetching: false,
  item: {},
  result: [],
  taskListResult: [],
  page: 1,
  size: 10,
  links: [],
};

const getTaskByProjectIdAction = createAsyncThunk(
  'task/getListByDashboard',
  async (projectId: string, { rejectWithValue }) => {
    try {
      const result = await api.get('/task/' + projectId, {
        params: {
          sort: 'ascend-createdOnDate',
          view: 'tree',
        },
      });
      return {
        ...result.data,
        content: (result.data?.content as ITaskDto[]).map((item) => new TaskDto.TaskDto(item)),
      };
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

const getListAllAction = createAsyncThunk(
  'task/getList',
  async (
    { id, sort, statusId }: { id?: string; sort?: string; statusId?: string },
    { rejectWithValue }
  ) => {
    try {
      const result = await api.get(`/task/list/${id}`, {
        params: {
          sort,
          statusId,
        },
      });
      return result.data?.content.map((item) => new TaskDto.TaskDto(item));
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

const getListFilterAllAction = createAsyncThunk(
  'task/getListFilter',
  async (
    {
      id,
      projectId,
      startDate,
      endDate,
      userId,
      sort,
    }: {
      id?: string;
      projectId?: string;
      startDate?: string;
      endDate?: string;
      userId?: string;
      sort?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const result = await api.get(
        `/task/list/${id}?startDate=${startDate}&endDate=${endDate}&projectId=${projectId}&userId=${userId}${
          sort ? `&sort=${sort}` : ''
        }`
      );
      return result.data?.content
      // .map((item) => new TaskDto.TaskDto(item));
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

const getTaskCompletedAction = createAsyncThunk(
  'task/getTaskComplete',
  async ({ sort }: { sort?: string }, { rejectWithValue }) => {
    try {
      const result = await api.get(`/task/cleared-task?sort=${sort}`);
      return result.data?.content.map((item) => new TaskDto.TaskDto(item));
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

const addTaskAction = createAsyncThunk(
  'task/addTask',
  async (payload: ITaskDto, { rejectWithValue }) => {
    try {
      const result = await api.post('/task', {
        ...payload,
      });
      return result.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

const addTaskActionAndUpdate = createAsyncThunk(
  'task/addTaskAndUpdate',
  async (payload: ITaskDto, { rejectWithValue, dispatch }) => {
    try {
      const result = await api
        .post('/task', {
          ...payload,
        })
        .then((res) => {
          dispatch(getTaskByProjectIdAction(payload.projectId as string));
          return res;
        });
      return result.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

const updateTaskAction = createAsyncThunk(
  'task/updateTask',
  async ({ id, statusId }: { id?: string; statusId?: string }, { rejectWithValue }) => {
    try {
      const result = await api.patch(`/task/${id}`, {
        statusId,
      });
      return result.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

const updateTaskClearance = createAsyncThunk(
  'task/updateClearanceTask',
  async ({ id, projectId }: { id: string; projectId: string }, { rejectWithValue }) => {
    try {
      const result = await api.patch(
        `/task/toggle-clearance/${id}?projectId=${projectId}&sort=ascend-createdOnDate&view=tree`
      );
      return result.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

const updateTaskListAction = createAsyncThunk(
  'task/updateTaskList',
  async (
    {
      id,
      startDate,
      endDate,
      title,
      content,
      userId,
      statusId,
      projectId,
    }: {
      id?: string;
      startDate?: string;
      endDate?: string;
      title?: string;
      content?: string;
      userId?: string;
      statusId?: string;
      projectId?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const result = await api.patch(`/task/${id}`, {
        startDate,
        endDate,
        title,
        content,
        userId,
        statusId,
        projectId,
      });
      return result.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);


const updateTaskListActionNoLoading = createAsyncThunk(
  'task/updateTaskListNoLoading',
  async (
    {
      id,
      startDate,
      endDate,
      title,
      content,
      userId,
      statusId,
      projectId,
    }: {
      id?: string;
      startDate?: string;
      endDate?: string;
      title?: string;
      content?: string;
      userId?: string;
      statusId?: string;
      projectId?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const result = await api.patch(`/task/${id}`, {
        startDate,
        endDate,
        title,
        content,
        userId,
        statusId,
        projectId,
      });
      return result.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);
const updateTaskContentAction = createAsyncThunk(
  'task/updateTaskContent',
  async (data: ITaskDto, { rejectWithValue, dispatch }) => {
    try {
      const result = await api
        .patch(`/task/${data.id}`, {
          ...data,
        })
        .then((res) => {
          dispatch(getTaskByProjectIdAction(data.projectId as string));
          return res;
        });
      return result.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

const updateOrderAction = createAsyncThunk(
  'task/updateOrder',
  async (
    {
      id,
      newNextOrder,
      newStatusId,
      startDate,
      endDate,
      projectId,
    }: {
      id?: string;
      newNextOrder?: number;
      newStatusId?: string;
      startDate: string;
      endDate: string;
      projectId: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await api.patch(`/task/order/${id}`, {
        newStatusId,
        newNextOrder,
        startDate,
        endDate,
        projectId,
      });
      return result.data.content;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

const updateProjectOrderAction = createAsyncThunk(
  'task/updateProjectOrder',
  async (
    { newOrders, id }: { newOrders: { id?: string; projectOrder: number }[]; id: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await api.patch(`/task/order-project/${id}`, {
        newOrders,
      });
      // return result.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

const updateTypeAction = createAsyncThunk(
  'task/updateType',
  async (
    { id, payload, projectId }: { id: string; payload: { id?: string }; projectId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await api.patch(`/task/order-type/${id}`, payload).then((res) => {
        dispatch(getTaskByProjectIdAction(projectId as string));
        return res;
      });
      // return result.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

const deleteTaskAction = createAsyncThunk(
  'task/deleteTask',
  async ({ id }: { id?: string }, { rejectWithValue }) => {
    try {
      const result = await api.delete(`/task/${id}`);
      return result.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    clearTask: (state) => {
      state.result = [];
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaskByProjectIdAction.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getTaskByProjectIdAction.fulfilled, (state, action) => {
        state.isFetching = false;
        state.result = (action.payload?.content as any) || [];
        state.page = (action.payload as any)?.page || 1;
        state.size = (action.payload as any)?.size || 10;
        state.links = (action.payload as any)?.links || [];
      })
      .addCase(getListAllAction.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getListAllAction.fulfilled, (state, action) => {
        state.isFetching = false;
        state.taskListResult = action.payload as any;
      })
      .addCase(getListFilterAllAction.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getListFilterAllAction.fulfilled, (state, action) => {
        state.isFetching = false;
        state.result = action.payload as any;
      })
      .addCase(getTaskCompletedAction.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getTaskCompletedAction.fulfilled, (state, action) => {
        state.isFetching = false;
        state.result = action.payload as any;
      })
      .addCase(addTaskAction.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addTaskAction.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(addTaskAction.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(updateTaskAction.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateTaskAction.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(updateTaskAction.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(updateTaskListAction.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateTaskListAction.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(updateTaskListAction.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(updateTaskContentAction.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateTaskContentAction.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(updateTaskContentAction.rejected, (state) => {
        state.isFetching = false;
      })
      // .addCase(updateOrderAction.pending, state => {
      //     state.isFetching = true
      // })
      .addCase(updateOrderAction.fulfilled, (state, action) => {
          // state.isFetching = false;
        state.result = action.payload as any;
      })
      // .addCase(updateOrderAction.rejected, (state) => {
      //     state.isFetching = false;
      // })
      .addCase(updateProjectOrderAction.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateProjectOrderAction.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(updateProjectOrderAction.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(deleteTaskAction.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteTaskAction.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(deleteTaskAction.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(updateTypeAction.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateTypeAction.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(updateTypeAction.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(updateTaskClearance.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateTaskClearance.fulfilled, (state, action) => {
        state.isFetching = false;
        state.result = action.payload?.content as any;
        state.page = (action.payload as any)?.page;
        state.size = (action.payload as any)?.size;
        state.links = (action.payload as any)?.links;
      })
      .addCase(updateTaskClearance.rejected, (state) => {
        state.isFetching = false;
      });
  },
});

export const taskAction = {
  getTaskByProjectIdAction,
  getListAllAction,
  addTaskAction,
  addTaskActionAndUpdate,
  updateTaskAction,
  deleteTaskAction,
  getListFilterAllAction,
  updateTaskListAction,
  updateTaskListActionNoLoading,
  updateTaskContentAction,
  getTaskCompletedAction,
  updateOrderAction,
  updateTaskClearance,
  updateTypeAction,
  updateProjectOrderAction,
  clearTask: taskSlice.actions.clearTask,
};

export const taskSelector = (state: RootState) => state['task'];

export default taskSlice.reducer;
