import { ROUTES } from 'constants/RouteConst';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../store';
import { dashboardDetailAction } from '../../store/dashboardDetailSlice';
import queryString from 'query-string';
import AvatarDefault from 'assets/images/userdefault.png';

const Header = (props) => {
  const userInfor = useSelector((state: RootState) => state.auth.authUser);
  const dashboard = useSelector((state: RootState) => state.dashboardDetail.result);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const match = useRouteMatch('/dashboard/:dashboardId');
  const dashboardId =
    (match?.params as any)?.dashboardId || queryString.parse(location.search).dashboardId;

  const showAvatar =
    location.pathname.includes('/change-password') ||
    location.pathname.includes('/confirm-change-password') ||
    location.pathname.includes('/invite-user') ||
    location.pathname.includes('/management');

  useEffect(() => {
    if (dashboardId) {
      dispatch(dashboardDetailAction.getListAllAction({ id: dashboardId }));
    }
  }, [dispatch, dashboardId]);

  return (
    <div className="header center-content" style={{ zIndex: 10000 }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-auto col-left">
            <div
              onClick={() => props.setSidebarCollapsed(!props.sidebarCollapsed)}
              className="menu-mobile"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3333 3.33398H2.66667C2.29848 3.33398 2 3.63246 2 4.00065C2 4.36884 2.29848 4.66732 2.66667 4.66732H13.3333C13.7015 4.66732 14 4.36884 14 4.00065C14 3.63246 13.7015 3.33398 13.3333 3.33398Z"
                  fill="#3CB371"
                />
                <path
                  d="M13.3333 7.33398H2.66667C2.29848 7.33398 2 7.63246 2 8.00065C2 8.36884 2.29848 8.66732 2.66667 8.66732H13.3333C13.7015 8.66732 14 8.36884 14 8.00065C14 7.63246 13.7015 7.33398 13.3333 7.33398Z"
                  fill="#3CB371"
                />
                <path
                  d="M13.3333 11.334H2.66667C2.29848 11.334 2 11.6325 2 12.0007C2 12.3688 2.29848 12.6673 2.66667 12.6673H13.3333C13.7015 12.6673 14 12.3688 14 12.0007C14 11.6325 13.7015 11.334 13.3333 11.334Z"
                  fill="#3CB371"
                />
              </svg>
            </div>
            {userInfor?.rank === 1 ? (
              <h3 className="title gx-mb-0">管理画面</h3>
            ) : (
              <h3
                style={{ cursor: 'pointer' }}
                className="title gx-mb-0"
                onClick={() => history.push(ROUTES.DASHBOARD_LIST)}
              >
                {dashboard?.dashboard?.name}
              </h3>
            )}
          </div>

          {!showAvatar && (
            <div className="col col-right">
              {dashboard?.members?.map((item, index) => (
                <span className="user-icon" key={index}>
                  {item.avatar ? (
                    <img
                      className="avatar"
                      style={{
                        zIndex: 2,
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                        minWidth: '32px',
                      }}
                      src={`${process.env.REACT_APP_API_URL}${item.avatar}`}
                      alt={''}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = AvatarDefault;
                      }}
                    />
                  ) : (
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="32" height="32" rx="16" fill="#CCCCCC" />
                      <path
                        d="M15.9998 15.9998C17.9642 15.9998 19.5553 14.4087 19.5553 12.4442C19.5553 10.4798 17.9642 8.88867 15.9998 8.88867C14.0353 8.88867 12.4442 10.4798 12.4442 12.4442C12.4442 14.4087 14.0353 15.9998 15.9998 15.9998ZM15.9998 17.7776C13.6265 17.7776 8.88867 18.9687 8.88867 21.3331V22.222C8.88867 22.7109 9.28867 23.1109 9.77756 23.1109H22.222C22.7109 23.1109 23.1109 22.7109 23.1109 22.222V21.3331C23.1109 18.9687 18.3731 17.7776 15.9998 17.7776Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
