import enLang from "./entries/en-US";
import viLang from "./entries/vi-VN";
import jaLang from "./entries/ja-JP";

const AppLocale = {
  vi: viLang,
  en: enLang,
  ja: jaLang
};

export default AppLocale;
