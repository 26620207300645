import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IMindmapDto } from "../mapping/Mindmap/mindmapDto";
import { api } from "../util/api";
import { RootState } from './index';
import { CreateMindmapDto, CreateMindmapLinkDto, UpdateMindmapDto } from '../api/services/index';
import MindmapDto from '../mapping/Mindmap/mindmapDto';
import { message } from 'antd';
import { taskAction } from './taskSlice';
import MindmapLinkDto from '../mapping/MindmapLink/MindmapLinkDto';

interface mindmapState {
    isFetching: boolean
    result: IMindmapDto
    list: IMindmapDto[]
}

const initialState: mindmapState = {
    isFetching: false,
    result: {},
    list: []
}

const getDetailAction = createAsyncThunk("mindmap/getList", async ({ id }: { id?: string }, { rejectWithValue }) => {
    try {
        const result = await api.get(`/mindmap/${id}`)
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const create = createAsyncThunk("mindmap/create", async (data: IMindmapDto & { projectId: string }, { rejectWithValue, dispatch }) => {
    try {
        const result = await api.post('/mindmap', data)
            .then(res => {
                dispatch(taskAction.getTaskByProjectIdAction(data.projectId as string))
                return res;
            })
        // message.success("Add mindmmap success");
        return new MindmapDto(result.data);
    } catch (e) {
        rejectWithValue(e);
    }
});

const createLink = createAsyncThunk("mindmap-links/create", async (data: CreateMindmapLinkDto & { projectId: string }, { rejectWithValue, dispatch }) => {
    try {
        const result = await api.post("/mindmap/link", data).then(res => {
            dispatch(taskAction.getTaskByProjectIdAction(data?.projectId as string));
            return res
        })
        // message.success("Link mindmmap success");
        return new MindmapLinkDto(result.data);
    } catch (e) {
        rejectWithValue(e);
    }
});

const update = createAsyncThunk("mindmap/update", async (data: UpdateMindmapDto & { id: string, projectId: string }, { rejectWithValue, dispatch }) => {
    try {
        const result = await api.patch('/mindmap/' + data?.id, data)
            .then(res => {
                dispatch(taskAction.getTaskByProjectIdAction(data.projectId as string))
                return res;
            })
        // message.success("Add mindmmap success");
        return new MindmapDto(result.data);
    } catch (e) {
        rejectWithValue(e);
    }
});

const unlink = createAsyncThunk("mindmap/delete", async (data: IMindmapDto & {projectId: string}, {rejectWithValue, dispatch}) => {
    try {
        const result = await api.delete("/mindmap/unlink/" + data.id)
        .then(res => {
            dispatch(taskAction.getTaskByProjectIdAction(data.projectId as string))
                return res;
        })
        return result
    } catch (e) {
        rejectWithValue(e);
    }
})

const remove = createAsyncThunk("mindmap/remove", async (id: string, { rejectWithValue, dispatch }) => {
    try {
        const result = await api.delete(`/mindmap/${id}`);
        return result;
    } catch (e) {
        rejectWithValue(e);
    }
});

export const mindmapSlice = createSlice({
    name: 'mindmap',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDetailAction.pending, state => {
                state.isFetching = true
            })
            .addCase(getDetailAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = action.payload as any;
            })
            .addCase(create.pending, state => {
                state.isFetching = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.isFetching = false;
                state.list = [...state.list as Array<IMindmapDto>, action.payload as IMindmapDto];
            })
            .addCase(update.pending, state => {
                state.isFetching = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.isFetching = false;
                // state.list = [...state.list as Array<IMindmapDto>, action.payload as IMindmapDto];
            })
            .addCase(unlink.pending, state => {
                state.isFetching = true
            })
            .addCase(unlink.fulfilled, (state, action) => {
                state.isFetching = false;
                // state.list = [...state.list as Array<IMindmapDto>, action.payload as IMindmapDto];
            })
            .addCase(unlink.rejected, (state, action) => {
                state.isFetching = false;
                // state.list = [...state.list as Array<IMindmapDto>, action.payload as IMindmapDto];
            })
            .addCase(remove.pending, state => {
                state.isFetching = true
            })
            .addCase(remove.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(remove.rejected, (state, action) => {
                state.isFetching = false;
            })
    },
});

export const mindmapAction = {
    getDetailAction,
    create,
    createLink,
    update,
    unlink,
    remove
}

export const mindmapSelector = (state: RootState) => state["mindmap"];

export default mindmapSlice.reducer;