import UtilDto, { IUtilDto } from "../UtilDto";
import UserDto from "../User/UserDto";
import StatusDto from "../Status/StatusDto";

export enum KPIUnit {
    PERCENT = '%',
    PIECE = '個',
    CASE = '件'
  }

export interface IKpiDto extends IUtilDto {
  name?: string;
  targetMonth?: Date;
  targetValue?: number;
  alertValue?: number;
  unit?: KPIUnit;
  formula?: string;
  dashboardId?: string;
  targetValueConversion?: number;
}



class KpiDto extends UtilDto {
    name?: string;
  targetMonth?: Date;
  targetValue?: number;
  alertValue?: number;
  unit?: string;
  formula?: string;
  dashboardId?: string;
  targetValueConversion?: number;

  constructor(data: IKpiDto) {
    super(data);
    this.setData(data);
  }

  setData(data: IKpiDto) {
    this.name = data.name || "";
    this.targetMonth = data.targetMonth;
    this.targetValue = data.targetValue || 0;
    this.alertValue = data.alertValue || 0;
    this.unit = data.unit || "";
    this.formula = data.formula || "";
    this.dashboardId = data.dashboardId || "";
    this.targetValueConversion = data.targetValueConversion;
  }
}

export default KpiDto;