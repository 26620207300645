import { ROUTES } from '../../constants/RouteConst';

const icon = {
  dashboard: (
    <path
      d="M4 14H6C6.55 14 7 13.55 7 13V11C7 10.45 6.55 10 6 10H4C3.45 10 3 10.45 3 11V13C3 13.55 3.45 14 4 14ZM4 19H6C6.55 19 7 18.55 7 18V16C7 15.45 6.55 15 6 15H4C3.45 15 3 15.45 3 16V18C3 18.55 3.45 19 4 19ZM4 9H6C6.55 9 7 8.55 7 8V6C7 5.45 6.55 5 6 5H4C3.45 5 3 5.45 3 6V8C3 8.55 3.45 9 4 9ZM9 14H20C20.55 14 21 13.55 21 13V11C21 10.45 20.55 10 20 10H9C8.45 10 8 10.45 8 11V13C8 13.55 8.45 14 9 14ZM9 19H20C20.55 19 21 18.55 21 18V16C21 15.45 20.55 15 20 15H9C8.45 15 8 15.45 8 16V18C8 18.55 8.45 19 9 19ZM8 6V8C8 8.55 8.45 9 9 9H20C20.55 9 21 8.55 21 8V6C21 5.45 20.55 5 20 5H9C8.45 5 8 5.45 8 6Z"
      fill="#FAF9F8"
    />
  ),
  project: (
    <path
      d="M22 8.0007C22 7.4507 21.55 7.0007 21 7.0007H14C13.45 7.0007 13 7.4507 13 8.0007C13 8.5507 13.45 9.0007 14 9.0007H21C21.55 9.0007 22 8.5507 22 8.0007ZM13 16.0007C13 16.5507 13.45 17.0007 14 17.0007H21C21.55 17.0007 22 16.5507 22 16.0007C22 15.4507 21.55 15.0007 21 15.0007H14C13.45 15.0007 13 15.4507 13 16.0007ZM10.47 4.6307C10.86 5.0207 10.86 5.6507 10.47 6.0407L6.23998 10.2907C5.84998 10.6807 5.21998 10.6807 4.81998 10.2907L2.69998 8.1607C2.513 7.97373 2.40796 7.72013 2.40796 7.4557C2.40796 7.32477 2.43375 7.19512 2.48385 7.07416C2.53396 6.9532 2.6074 6.84329 2.69998 6.7507C2.79256 6.65812 2.90247 6.58468 3.02344 6.53458C3.1444 6.48447 3.27405 6.45868 3.40498 6.45868C3.66941 6.45868 3.923 6.56373 4.10998 6.7507L5.52998 8.1707L9.06998 4.6307C9.44998 4.2507 10.09 4.2507 10.47 4.6307ZM10.48 12.6407C10.87 13.0307 10.87 13.6607 10.48 14.0507L6.24998 18.3007C5.85998 18.6907 5.22998 18.6907 4.82998 18.3007L2.69998 16.1607C2.6074 16.0681 2.53396 15.9582 2.48385 15.8372C2.43375 15.7163 2.40796 15.5866 2.40796 15.4557C2.40796 15.3248 2.43375 15.1951 2.48385 15.0742C2.53396 14.9532 2.6074 14.8433 2.69998 14.7507C2.79256 14.6581 2.90247 14.5847 3.02344 14.5346C3.1444 14.4845 3.27405 14.4587 3.40498 14.4587C3.53591 14.4587 3.66556 14.4845 3.78652 14.5346C3.90749 14.5847 4.0174 14.6581 4.10998 14.7507L5.52998 16.1707L9.06998 12.6307C9.44998 12.2507 10.09 12.2507 10.48 12.6407Z"
      fill="#FAF9F8"
    />
  ),
  taskboard: (
    <path
      d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM8 17C7.45 17 7 16.55 7 16V8C7 7.45 7.45 7 8 7C8.55 7 9 7.45 9 8V16C9 16.55 8.55 17 8 17ZM12 12C11.45 12 11 11.55 11 11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11C13 11.55 12.55 12 12 12ZM16 15C15.45 15 15 14.55 15 14V8C15 7.45 15.45 7 16 7C16.55 7 17 7.45 17 8V14C17 14.55 16.55 15 16 15Z"
      fill="#2E8B57"
    />
  ),
  notification: (
    <path
      d="M12.0001 22C13.1001 22 14.0001 21.1 14.0001 20H10.0001C10.0001 20.5304 10.2108 21.0391 10.5858 21.4142C10.9609 21.7893 11.4696 22 12.0001 22ZM18.0001 16V11C18.0001 7.93 16.3601 5.36 13.5001 4.68V4C13.5001 3.17 12.8301 2.5 12.0001 2.5C11.1701 2.5 10.5001 3.17 10.5001 4V4.68C7.63005 5.36 6.00005 7.92 6.00005 11V16L4.71005 17.29C4.08005 17.92 4.52005 19 5.41005 19H18.5801C19.4701 19 19.9201 17.92 19.2901 17.29L18.0001 16Z"
      fill="#FAF9F8"
    />
  ),
  mypage: (
    <path
      d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V18C20 15.34 14.67 14 12 14Z"
      fill="#FAF9F8"
    />
  ),
  dashboardSetting: (
    <path
      d="M19.4999 12C19.4999 11.77 19.4899 11.55 19.4699 11.32L21.3299 9.91C21.7299 9.61 21.8399 9.05 21.5899 8.61L19.7199 5.38C19.6 5.16818 19.4061 5.00814 19.1753 4.93062C18.9446 4.8531 18.6934 4.86356 18.4699 4.96L16.3199 5.87C15.9499 5.61 15.5599 5.38 15.1499 5.19L14.8599 2.88C14.7999 2.38 14.3699 2 13.8699 2H10.1399C9.62991 2 9.19991 2.38 9.13991 2.88L8.84991 5.19C8.43991 5.38 8.04991 5.61 7.67991 5.87L5.52991 4.96C5.06991 4.76 4.52991 4.94 4.27991 5.38L2.40991 8.62C2.15991 9.06 2.26991 9.61 2.66991 9.92L4.52991 11.33C4.48846 11.779 4.48846 12.231 4.52991 12.68L2.66991 14.09C2.26991 14.39 2.15991 14.95 2.40991 15.39L4.27991 18.62C4.52991 19.06 5.06991 19.24 5.52991 19.04L7.67991 18.13C8.04991 18.39 8.43991 18.62 8.84991 18.81L9.13991 21.12C9.19991 21.62 9.62991 22 10.1299 22H13.8599C14.3599 22 14.7899 21.62 14.8499 21.12L15.1399 18.81C15.5499 18.62 15.9399 18.39 16.3099 18.13L18.4599 19.04C18.9199 19.24 19.4599 19.06 19.7099 18.62L21.5799 15.39C21.8299 14.95 21.7199 14.4 21.3199 14.09L19.4599 12.68C19.4899 12.45 19.4999 12.23 19.4999 12ZM12.0399 15.5C10.1099 15.5 8.53991 13.93 8.53991 12C8.53991 10.07 10.1099 8.5 12.0399 8.5C13.9699 8.5 15.5399 10.07 15.5399 12C15.5399 13.93 13.9699 15.5 12.0399 15.5Z"
      fill="#FAF9F8"
    />
  ),
};

const routes = [
  {
    name: 'dashboard',
    path: '/dashboard/:dashboardId',
    icon: icon['dashboard'],
  },
  {
    name: 'project',
    path: ROUTES.PROJECT,
    icon: icon['project'],
  },
  {
    name: 'taskboard',
    path: ROUTES.TASKBOARD,
    icon: icon['taskboard'],
  },
  {
    name: 'mypage',
    path: ROUTES.USER_LIST,
    icon: icon['mypage'],
  },
  {
    name: 'dashboardSetting',
    path: ROUTES.DASHBOARD_SETTING,
    icon: icon['dashboardSetting'],
  },
];

const iconManagement = {
  dashboard: (
    <path
      d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM8 17C7.45 17 7 16.55 7 16V11C7 10.45 7.45 10 8 10C8.55 10 9 10.45 9 11V16C9 16.55 8.55 17 8 17ZM12 17C11.45 17 11 16.55 11 16V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V16C13 16.55 12.55 17 12 17ZM16 17C15.45 17 15 16.55 15 16V14C15 13.45 15.45 13 16 13C16.55 13 17 13.45 17 14V16C17 16.55 16.55 17 16 17Z"
      fill="#FAF9F8"
    />
  ),
  company: (
    <path
      d="M12 7V5C12 3.9 11.1 3 10 3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V9C22 7.9 21.1 7 20 7H12ZM10 19H4V17H10V19ZM10 15H4V13H10V15ZM10 11H4V9H10V11ZM10 7H4V5H10V7ZM20 19H12V9H20V19ZM18 11H14V13H18V11ZM18 15H14V17H18V15Z"
      fill="#FAF9F8"
    />
  ),
};

const routesManagement = [
  {
    name: 'dashboard',
    path: ROUTES.MANAGEMENT_DASHBOARD,
    icon: iconManagement['dashboard'],
  },
  {
    name: 'company',
    path: ROUTES.MANAGEMENT_COMPANY,
    icon: iconManagement['company'],
  }
];

const ROUTES_SIDEBAR = {
  routes,
  routesManagement,
};

export default ROUTES_SIDEBAR;
