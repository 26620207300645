import { Form, Input } from 'antd';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../constants/RouteConst';
import Logo from '../../../assets/images/logo.png';

const FormItem = Form.Item;

const ConfirmForgotPassword = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      form.setFieldsValue(location.state);
    } else {
      form.setFieldsValue(JSON.parse(localStorage.getItem('email-forgot') as string));
    }
  }, [location, form]);

  const handleReturnLogin = () => {
    history.push(ROUTES.SIGN_IN);
    if (localStorage.getItem('email-forgot')) {
      localStorage.removeItem('email-forgot');
    }
  };

  return (
    <div id="authentication" className="page-forgot-password">
      <div className="container">
        <div className="sign-form">
          <div className="image">
            <img src={Logo} alt="logo" />
          </div>
          <div className="title">
            <h3>パスワード再設定用URL発行</h3>
          </div>
          <p className="sub-title">認証メールをお送りしました</p>
          <p className="desc confirm-forgot-desc">
            メールをご確認頂き、
            <br />
            メールに記載されたURLをクリックして、
            <br />
            パスワードの再設定を完了してください。
          </p>
          <Form name="forgotPassword" form={form}>
            <FormItem className="form-group">
              <label>メールアドレス</label>
              <FormItem name="email">
                <Input placeholder="メールアドレス" className="form-control" readOnly />
              </FormItem>
            </FormItem>

            <FormItem className="confirm-forgot-button">
              <button className="btn btn-submit " type="button" onClick={handleReturnLogin}>
                ログイン画面に戻る
              </button>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ConfirmForgotPassword;
