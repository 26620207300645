import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IDashboardDetailDto } from "../mapping/DashboardDetail/DashboardDetailDto";
import { api } from "../util/api";
import { RootState } from './index';
import ProjectDto from 'mapping/Project/ProjectDto';
import StatusDto from '../mapping/Status/StatusDto';
import UserDto from '../mapping/User/UserDto';
import DashboardDto from "mapping/Dashboard/DashboardDto";
import DashboardDetailDto from '../mapping/DashboardDetail/DashboardDetailDto';

interface dashboardDetailState {
    isFetching: boolean
    item: IDashboardDetailDto
    result: IDashboardDetailDto
}

const initialState: dashboardDetailState = {
    isFetching: false,
    item: {},
    result: {}
}

const getListAllAction = createAsyncThunk("dashboardDetail/getList", async ({id} : {id?: string}, { rejectWithValue }) => {
    try {
        const result = await api.get(`/dashboard/${id}`)
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const updateToogleAction = createAsyncThunk("dashobard/deleteDashboard", async (data: { id: string }, { rejectWithValue }) => {
    try {
        const result = await api.patch("/dashboard/toggle-task/" + data.id)
        return new DashboardDetailDto(result.data as IDashboardDetailDto);
    } catch (e) {
        rejectWithValue(e);
    }
})

export const dashboardDetailSlice = createSlice({
    name: 'dashboardDetail',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListAllAction.pending, state => {
                state.isFetching = true
            })
            .addCase(getListAllAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = action.payload as any;
            })
            .addCase(getListAllAction.rejected, (state, action) => {
                state.isFetching = false;
            })
            .addCase(updateToogleAction.pending, (state, action) => {
                state.isFetching = true;
                // state.result = action.payload as any;
            })
            .addCase(updateToogleAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = {...state.result, ...action.payload as any};
            })
            .addCase(updateToogleAction.rejected, (state) => {
                state.isFetching = false;
            })
    },
});

export const dashboardDetailAction = {
    getListAllAction,
    updateToogleAction
}

export const dashboardDetailSelector = (state: RootState) => state["dashboardDetail"];

export default dashboardDetailSlice.reducer;