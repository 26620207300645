import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DashboardDto, { IDashboardMemberDto } from "../mapping/DashboardMember/dashboardMemberDto";
import { api } from "../util/api";
import { RootState } from './index';
import DashboardMemberDto from '../mapping/DashboardMember/dashboardMemberDto';

interface DashboardState {
    isFetching: boolean
    item: IDashboardMemberDto
    result: IDashboardMemberDto[],
    page: number,
    size: number
}

const initialState: DashboardState = {
    isFetching: false,
    item: {},
    result: [],
    page: 1,
    size: 0
}

const getListByDashboardAction = createAsyncThunk("dashboardMember/getList", async (id: string, { rejectWithValue }) => {
    try {
        return await api.get("/dashboard-member/list/" + id).then(result => {
            return {
                ...result.data,
                content: (result.data?.content as IDashboardMemberDto[]).map(item => new DashboardMemberDto(item) || {})
            }
        });
    } catch (e) {
        rejectWithValue(e);
    }
})

export const dashboardMemberSlice = createSlice({
    name: 'dashboardMember',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListByDashboardAction.pending, state => {
                state.isFetching = true
            })
            .addCase(getListByDashboardAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = (action.payload  as any)?.content;
                state.page = (action.payload  as any)?.page;
                state.size = (action.payload  as any)?.size;
            })
    },
});

export const dashboardMemberAction = {
    getListByDashboardAction
}

export const dashboardMemberSelector = (state: RootState) => state["dashboardMember"];

export default dashboardMemberSlice.reducer;