import UtilDto, { IUtilDto } from "../UtilDto";
import UserDto from "../User/UserDto";
import StatusDto from "../Status/StatusDto";
import DashboardDto, { IDashboardDto } from "../Dashboard/DashboardDto";

export interface IDashboardMemberDto extends IUtilDto {
  enableMailNoti?: boolean;
  dashboardId?: string;
  enableNotiMemberNotUpdateStatus?: boolean;
  enableMailNotiByComment?: boolean;
  enableNotiMemberExpiredTask?: boolean;
  enableNotiOutOfTask?: boolean;
  enableMailNotiChangeKpi?: boolean;
  timeNotiMemberNotUpdateStatus?: number;
  userId?: string;
  //   dashboardId?: string;
  user?: any;
  dashboard?: IDashboardDto;
}

class DashboardMemberDto extends UtilDto {
  enableMailNoti?: boolean;
  dashboardId?: string;
  enableNotiMemberNotUpdateStatus?: boolean;
  enableMailNotiByComment?: boolean;
  enableNotiMemberExpiredTask?: boolean;
  enableNotiOutOfTask?: boolean;
  enableMailNotiChangeKpi?: boolean;
  timeNotiMemberNotUpdateStatus?: number;
  userId?: string;
  user?: any;
  dashboard?: IDashboardDto;

  constructor(data: IDashboardMemberDto) {
    super(data);
    this.setData(data);
  }

  setData(data: IDashboardMemberDto) {
    this.enableMailNoti = data?.enableMailNoti || false;
    this.dashboardId = data?.dashboardId || "";
    this.enableNotiMemberNotUpdateStatus = data?.enableNotiMemberNotUpdateStatus || false;
    this.enableMailNotiByComment = data?.enableMailNotiByComment || false;
    this.enableNotiMemberExpiredTask = data?.enableNotiMemberExpiredTask || false;
    this.enableNotiOutOfTask = data?.enableNotiOutOfTask || false;
    this.enableMailNotiChangeKpi = data?.enableMailNotiChangeKpi || false;
    this.timeNotiMemberNotUpdateStatus = data?.timeNotiMemberNotUpdateStatus || 0;
    this.userId = data?.userId || "";
    this.user = data?.user || {};
    this.dashboard = new DashboardDto(data?.dashboard as IDashboardDto) || {};
  }

  getData() {
    return { ...this };
  }
}

export default DashboardMemberDto;
