import { ROUTES } from 'constants/RouteConst';
import { STORAGES } from 'constants/StorageConst';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { AuthUser, me } from 'store/authSlice';

const ConfirmRecoverPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleSubmit = async () => {
    const user: AuthUser = {
      id: (location.state as { data: AuthUser }).data.id,
      email: (location.state as { data: AuthUser }).data.email,
      rank: (location.state as { data: AuthUser }).data.rank,
      companyId: (location.state as { data: AuthUser }).data.companyId,
    };

    localStorage.setItem(STORAGES.USER, JSON.stringify(user));
    localStorage.setItem(
      STORAGES.TOKEN,
      (location.state as { data: { token: string } }).data.token
    );

    await dispatch(me());

    history.push(ROUTES.DASHBOARD_LIST);
  };

  return (
    <div id="authentication" className="page-changed-password">
      <div className="container">
        <div className="sign-form">
          <div className="title">
            <h3>パスワードを変更しました</h3>
          </div>
          <p className="desc">
            パスワードの変更が完了しました。
            <br />
            引き続きサービスをご利用ください。
          </p>
          <button className="btn btn-to-dashboard" onClick={handleSubmit}>
            ダッシュボードへ
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRecoverPassword;
