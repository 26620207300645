import { Layout } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { RootState } from "../../store";
import SidebarContent from "./SidebarContent";

const { Sider } = Layout;

const Sidebar = ({ sidebarCollapsed, setSidebarCollapsed, params }) => {
  const { themeType, navStyle, width } = useSelector(
    (state: RootState) => state.setting
  );

  useEffect(() => {
    setSidebarCollapsed(navStyle === NAV_STYLE_MINI_SIDEBAR);
  }, [navStyle]);

  let drawerStyle = "gx-collapsed-sidebar";

  if (navStyle === NAV_STYLE_FIXED) {
    drawerStyle = "";
  } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar gx-mini-custom-sidebar";
  } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
    drawerStyle = "gx-custom-sidebar";
  } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar";
  } else if (navStyle === NAV_STYLE_DRAWER) {
    drawerStyle = "gx-collapsed-sidebar";
  }
  if (
    (navStyle === NAV_STYLE_FIXED ||
      navStyle === NAV_STYLE_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) &&
    width < TAB_SIZE
  ) {
    drawerStyle = "gx-collapsed-sidebar";
  }

  return (
    <Sider
      className={`gx-app-sidebar ${drawerStyle} ${
        themeType !== THEME_TYPE_LITE ? "gx-layout-sider-dark" : null
      } task-board ${!sidebarCollapsed ? "open-sidebar" : ""}`}
      trigger={null}
      collapsed={
        width < TAB_SIZE
          ? false
          : !sidebarCollapsed || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR
      }
      theme={themeType === THEME_TYPE_LITE ? "light" : "dark"}
      collapsible
    >
      <SidebarContent
        params={params}
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
    </Sider>
  );
};
export default Sidebar;
