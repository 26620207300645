import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISubKPIDto } from "../mapping/SubKPI/SubKPIDto";
import { api } from "../util/api";
import { RootState } from './index';

interface SubKPIState {
    isFetching: boolean
    item: ISubKPIDto
    result: ISubKPIDto[]
}

const initialState: SubKPIState = {
    isFetching: false,
    item: {},
    result: []
}

const getListSubKpiAction = createAsyncThunk("SubKPI/getList", async (payload: {kpiId: string, userId: string}, { rejectWithValue }) => {
    try {
        const result = await api.get(`/sub-kpi/list/${payload.kpiId}/${payload.userId}`)
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})


const getListAllAction = createAsyncThunk("SubKPI/getList", async (id: string, { rejectWithValue }) => {
    try {
        const result = await api.get(`/sub-kpi/list/${id}`)
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const addSubKPIAction = createAsyncThunk("SubKPI/addSubKPI", async ({data, date, dashboardId} : {data?: ISubKPIDto[], date: string, dashboardId: string} , { rejectWithValue }) => {
    try {
        const result = await api.post("/sub-kpi/batch", {
            batch: data,
            date ,
            dashboardId
        })
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const deleteSubKPIAction = createAsyncThunk("SubKPI/deleteSubKPI", async ({id, dashboardId} : {id?: string, dashboardId?: string} , { rejectWithValue }) => {
    try {
        const result = await api.delete(`/sub-kpi/${id}/${dashboardId}`)
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

export const subKPISlice = createSlice({
    name: 'SubKPI',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListAllAction.pending, state => {
                state.isFetching = true
            })
            .addCase(getListAllAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = action.payload as any;
            })
            .addCase(addSubKPIAction.pending, state => {
                state.isFetching = true
            })
            .addCase(addSubKPIAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(addSubKPIAction.rejected, (state) => {
                state.isFetching = false;
            })
            .addCase(deleteSubKPIAction.pending, state => {
                state.isFetching = true
            })
            .addCase(deleteSubKPIAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(deleteSubKPIAction.rejected, (state) => {
                state.isFetching = false;
            })
    },
});

export const subKPIAction = {
    getListAllAction,
    getListSubKpiAction,
    addSubKPIAction,
    deleteSubKPIAction
}

export const subKPISelector = (state: RootState) => state["subKPI"];

export default subKPISlice.reducer;