import UtilDto, { IUtilDto } from "../UtilDto";

export interface IDashboardDto extends IUtilDto {
  name?: string;
  description?: string;
  companyId?: string;
  taskToggle?: boolean
}

class DashboardDto extends UtilDto {
    name?: string;
  description?: string;
  companyId?: string;
  taskToggle?: boolean

  constructor(data: IDashboardDto) {
    super(data);
    this.setData(data);
  }

  setData(data: IDashboardDto) {
    this.name = data?.name || "";
    this.description = data?.description || "";
    this.companyId = data?.companyId || "";
    this.taskToggle = data?.taskToggle || false
  }
}

export default DashboardDto;