import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Input } from 'antd';
import queryString from 'query-string';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import Logo from '../../../assets/images/logo.png';
import { ROUTES } from '../../../constants/RouteConst';
import { RootState, useAppDispatch } from '../../../store';
import {
  AuthPayload,
  login,
  setDefaultError,
  setDefaultStoreDataForm,
} from '../../../store/authSlice';

const FormItem = Form.Item;

const schema = yup.object().shape({
  email: yup
    .string()
    .required('メールアドレスを入力してください')
    .email('メールアドレスの形式が正しくありません'),
  password: yup
    .string()
    .required('パスワードを入力してください')
    .min(8, '半角英数8文字以上で入力して下さい'),
});

const SignIn = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const loginError = useSelector((state: RootState) => state.auth.loginError);
  const storeDataForm = useSelector((state: RootState) => state.auth.storeDataForm);
  const form = useForm<AuthPayload>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: (queryParams.email as string) || storeDataForm?.email || '',
      password: storeDataForm?.password || '',
    },
    shouldFocusError: false,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (storeDataForm) {
      dispatch(setDefaultStoreDataForm());
    }
  }, [dispatch]);

  const onFinish = async (values: AuthPayload) => {
    try {
      const userInfor = await dispatch(login(values)).unwrap();
      history.push(userInfor?.rank === 1 ? ROUTES.MANAGEMENT_DASHBOARD : ROUTES.DASHBOARD_LIST);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return (
    <div id="authentication">
      <div className="container">
        <div className="sign-form">
          <div className="image">
            <img src={Logo} alt="logo" />
          </div>
          <div className="title">
            <h3>ログイン</h3>
            <p className="error-msg">{loginError}</p>
          </div>
          <Form name="signin" onFinish={handleSubmit(onFinish)}>
            <FormItem
              className="form-group"
              validateStatus={errors.email && 'error'}
              help={errors.email?.message}
            >
              <label>メールアドレス</label>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <Input {...field} placeholder="メールアドレス" className="form-control" />
                )}
              />
            </FormItem>
            <FormItem
              className="form-group"
              validateStatus={errors.password && 'error'}
              help={errors.password?.message}
            >
              <label>パスワード</label>
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <Input.Password
                    {...field}
                    placeholder="パスワード"
                    className="form-control form-password"
                  />
                )}
              />
            </FormItem>
            <FormItem>
              <button className="btn btn-submit" type="submit">
                ログイン
              </button>
            </FormItem>
            <Link
              to={ROUTES.FORGOT_PASSWORD}
              className="has-account d-block text-center"
              onClick={() => dispatch(setDefaultError())}
            >
              パスワードを忘れた方はこちら
            </Link>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
