import { Form, Input } from 'antd';
import { message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { ROUTES } from '../../../constants/RouteConst';
import { api } from '../../../util/api';
import Logo from '../../../assets/images/logo.png';

const FormItem = Form.Item;

const ForgotPassword = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const response = await api.post('/auth/send-mail-forgot-password', { ...values });
      history.push({
        pathname: ROUTES.CONFIRM_FORGOT_PASSWORD,
        state: JSON.parse(response.config.data),
      });
      localStorage.setItem('email-forgot', response.config.data);
    } catch (error) {
      const errorObj = JSON.parse((error as any).request.response);
      if (errorObj.statusCode === 404 && errorObj.message === 'EMAIL_NOT_FOUND') {
        message.error('登録されているメールアドレスと一致しません');
      } else if (errorObj.message === 'USER_NOT_ACTIVATED') {
        message.error('登録されているメールアドレスと一致しません');
      } else {
        message.error('内部サーバーエラー');
      }
    }
  };

  const validateEmail = (_: any, value: string) => {
    const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!value) {
      return Promise.reject('メールアドレスを入力してください');
    } else if (!regexEmail.test(value)) {
      return Promise.reject('メールアドレスの形式が正しくありません');
    } else {
      return Promise.resolve();
    }
  };

  return (
    <div id="authentication" className="page-forgot-password">
      <div className="container">
        <div className="sign-form">
          <div className="image">
            <img src={Logo} alt="logo" />
          </div>
          <div className="title">
            <h3>パスワード再設定用URL発行</h3>
          </div>
          <p className="desc">
            登録されているメールアドレスを入力してください。
            パスワードリセットメールを送信いたします。
          </p>
          <Form name="forgotPassword" onFinish={onFinish} form={form}>
            <FormItem className="form-group">
              <label>メールアドレス</label>
              <FormItem name="email" rules={[{ validator: validateEmail }]}>
                <Input placeholder="メールアドレス" className="form-control" />
              </FormItem>
            </FormItem>

            <FormItem>
              <button className="btn btn-submit" type="submit">
                パスワード再発行メールを送信
              </button>
            </FormItem>
            <Link to={ROUTES.SIGN_IN} className="has-account d-block">
              すでにアカウントをお持ちの方はこちら
            </Link>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
