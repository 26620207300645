import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StatusDto, { IStatusDto } from "../mapping/Status/StatusDto";
import { api } from "../util/api";
import { RootState } from './index';

interface StatusState {
    isFetching: boolean
    item: IStatusDto
    result: IStatusDto[]
}

const initialState: StatusState = {
    isFetching: false,
    item: {},
    result: []
}

const getListAllAction = createAsyncThunk("status/getList", async ({id, sort} : {id?: string, sort?: string}, { rejectWithValue }) => {
    try {
        const result = await api.get(sort ? `/status/list/${id}?sort=${sort}` : `/status/list/${id}`)
        return {
            ...result.data,
            content: (result.data?.content as IStatusDto[]).map(item => new StatusDto(item) || {})
        }
    } catch (e) {
        rejectWithValue(e);
    }
})

const addStatusAction = createAsyncThunk("status/addStatus", async (payload: IStatusDto , { rejectWithValue }) => {
    try {
        const result = await api.post("/status", {
            ...payload
        })
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const updateStatusAction = createAsyncThunk("status/updateStatus", async ({id, name, colorCode} : {id?: string, name?: string, colorCode?: string} , { rejectWithValue }) => {
    try {
        const result = await api.patch(`/status/${id}`, {
            name,
            colorCode
        })
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

const deleteStatusAction = createAsyncThunk("status/deleteStatus", async ({id} : {id?: string} , { rejectWithValue }) => {
    try {
        const result = await api.delete(`/status/${id}`)
        return result.data;
    } catch (e) {
        rejectWithValue(e);
    }
})

export const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListAllAction.pending, state => {
                state.isFetching = true
            })
            .addCase(getListAllAction.fulfilled, (state, action) => {
                state.isFetching = false;
                state.result = (action.payload  as any)?.content;
            })
            .addCase(addStatusAction.pending, state => {
                state.isFetching = true
            })
            .addCase(addStatusAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(addStatusAction.rejected, (state) => {
                state.isFetching = false;
            })
            .addCase(updateStatusAction.pending, state => {
                state.isFetching = true
            })
            .addCase(updateStatusAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(updateStatusAction.rejected, (state) => {
                state.isFetching = false;
            })
            .addCase(deleteStatusAction.pending, state => {
                state.isFetching = true
            })
            .addCase(deleteStatusAction.fulfilled, (state, action) => {
                state.isFetching = false;
            })
            .addCase(deleteStatusAction.rejected, (state) => {
                state.isFetching = false;
            })
    },
});

export const statusAction = {
    getListAllAction,
    addStatusAction,
    updateStatusAction,
    deleteStatusAction
}

export const statusSelector = (state: RootState) => state["status"];

export default statusSlice.reducer;