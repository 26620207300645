import UtilDto, { IUtilDto } from "../UtilDto";
import UserDto from "../User/UserDto";
import StatusDto from "../Status/StatusDto";
import ProjectDto from "../Project/ProjectDto";
import { IMindmapDto } from "../Mindmap/mindmapDto";

export interface ITaskboardDto extends IUtilDto {
  contentTask?: Array<TaskDto>;
}

export enum TaskIn {
  TODO = 0,
  MINDMAP = 1
}

class TaskboardDto extends UtilDto {
  contentTask?: Array<TaskDto>;

  constructor(data: ITaskboardDto) {
    super(data);
    this.setData(data);
  }

  setData(data: ITaskboardDto) {
    this.contentTask = data.contentTask;
  }
}

export interface ITaskDto extends IUtilDto {
  type?: string;
  colorCode?: string;
  order?: number;
  projectOrder?: number;
  startDate?: string;
  endDate?: string;
  title?: string;
  content?: string;
  user?: UserDto;
  userId?: string;
  status?: StatusDto;
  project?: ProjectDto;
  projectId?: string;
  mindmaps?: IMindmapDto[];
  subTasks?: ITaskDto[];
  mainTaskId?: string,
  dashboardId?: string;
  clearanceDate?: string;
  clearance?: boolean;
  dashboardTemplateId?: string;
  statusId?: string;
  mindmapCreateId?: string;
  taskIn?: TaskIn;
}

class TaskDto extends UtilDto {
  type?: string;
  colorCode?: string;
  order?: number;
  projectOrder?: number;
  startDate?: string;
  endDate?: string;
  title?: string;
  content?: string;
  user?: UserDto;
  userId?: string;
  status?: StatusDto;
  project?: ProjectDto;
  projectId?: string;
  mindmaps?: IMindmapDto[];
  dashboardId?: string;
  clearanceDate?: string;
  subTasks?: ITaskDto[];
  mainTaskId?: string;
  clearance?: boolean = false;
  dashboardTemplateId?: string;
  statusId?: string;
  mindmapCreateId?: string;
  taskIn?: TaskIn;

  constructor(data: ITaskDto) {
    super(data);
    this.setData(data);
  }

  setData(data: ITaskDto) {
    this.type = data?.type || "main";
    this.colorCode = data?.colorCode || "";
    this.order = data?.order;
    this.projectOrder = data?.projectOrder;
    this.startDate = data?.startDate || "";
    this.endDate = data?.endDate || "";
    this.title = data?.title || "";
    this.content = data?.content || "";
    this.user = data?.user;
    this.userId = data?.userId;
    this.status = data?.status;
    this.subTasks = (data?.subTasks || []).map(task => new TaskDto(task)) || [];
    this.projectId = data?.projectId || "";
    this.mainTaskId = data?.mainTaskId || "";
    this.project = data?.project;
    this.mindmaps = data?.mindmaps || [];
    this.dashboardId = data?.dashboardId || "";
    this.subTasks = (data?.subTasks as ITaskDto[] || []).map(task => new TaskDto(task)) || []
    this.clearanceDate = data?.clearanceDate || "";
    this.clearance = data.clearance || false;
    this.dashboardTemplateId = data.dashboardTemplateId;
    this.statusId = data.statusId;
    this.mindmapCreateId = data.mindmapCreateId;
    this.taskIn = data.taskIn;
  }
}

export default { TaskboardDto, TaskDto };
